.is--menu {
    .rwd002-container,
    .rwd002-detail {
        margin-top: 0;
        padding-inline: 0;
        @include media-down(lg) {
            margin-bottom: 0;
        }
    }
    .rwd002-detail__entry {
        max-width: inherit;
        padding-inline: 0;
    }
}
.menu-link {
    @include list-link();
    position: relative;
    z-index: 1;
    margin-bottom: 65px;
    @include media-up(lg) {
        margin-top: -45px;
    }
}
.menu-link__bottom {
    @include list-link();
    padding: 0 !important;
    li {
        a {
            @media (hover: hover) {
                &:hover {
                    color: #282828;
                    background-color: #e1ded3;
                }
            }
        }
        &._current {
            a {
                color: #282828;
                background-color: #e1ded3;
            }
        }
    }
}
.menu-container {
    padding-inline: map-get($spacers, 3);
    &__inner {
        width: 100%;
        max-width: 1100px;
        margin-inline: auto;
    }
}
.drink-container {
    padding-inline: map-get($spacers, 3);
    &__inner {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
}
.menu-lead {
    margin-bottom: 75px;
    @include media-down(md) {
        margin-bottom: 40px;
    }
    p {
        margin-bottom: 15px;
        font-size: calc(20 / 16 * $font-size-base);
        text-align: center;
        letter-spacing: 0.15em;
        line-height: calc(26 / 16);
        @include media-down(md) {
            margin-bottom: 12px;
            font-size: calc(16 / 16 * $font-size-base);
        }
    }
    ._lead {
        margin-bottom: 38px;
        @include media-down(md) {
            margin-bottom: 25px;
        }
    }
    b,
    strong {
        font-size: calc(18 / 16 * $font-size-base);
        letter-spacing: 0.1em;
        line-height: calc(36 / 18);
        @include media-down(md) {
            font-size: calc(15 / 16 * $font-size-base);
            line-height: calc(29 / 15);
        }
    }
    ._small {
        font-size: calc(15 / 16 * $font-size-base);
        line-height: calc(21 / 12);
        @include media-down(md) {
            font-size: calc(12 / 16 * $font-size-base);
        }
    }
}
.drink-image {
    position: relative;
    width: 100%;
    max-width: 960px;
    margin-inline: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 90px;
    @include media-down(md) {
        margin-bottom: 40px;
    }
    &__title {
        position: absolute;
        @include font-en();
        margin: 0;
        font-size: calc(35 / 16 * $font-size-base);
        color: #282828;
        letter-spacing: 0;
        @include media-down(md) {
            font-size: calc(25 / 16 * $font-size-base);
        }
    }
    img {
        width: 100%;
        height: auto;
    }
}
[class*='menu-pattern'] {
    ._menu-price {
        display: flex;
        align-items: flex-end;
        margin-left: auto;
        margin-bottom: 0;
        line-height: 1;
        ._number {
            @include font-en();
            font-size: calc(20 / 16 * $font-size-base);
            letter-spacing: 0;
            @include media-down(md) {
                font-size: calc(16 / 16 * $font-size-base);
            }
        }
        ._tax {
            position: relative;
            top: -2px;
            margin-left: 4px;
            font-size: calc(12 / 16 * $font-size-base);
            letter-spacing: 0.15em;
            @include media-down(md) {
                margin-left: 2px;
                font-size: calc(10 / 16 * $font-size-base);
            }
        }
    }
}
.menu-pattern-1 {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
        display: flex;
        margin-bottom: 100px;
        @include media-up(lg) {
            justify-content: space-between;
            align-items: flex-start;
            gap: 40px;
        }
        @include media-down(lg) {
            flex-direction: column;
            gap: 30px;
        }
        @include media-down(md) {
            margin-bottom: 55px;
        }
        img {
            max-width: 550px;
            height: auto;
            @include media-up(lg) {
                width: 50%;
            }
            @include media-down(lg) {
                width: 100%;
                margin: auto;
            }
        }
    }
    ._name {
        margin-bottom: 15px;
        font-size: calc(22 / 16 * $font-size-base);
        font-weight: bold;
        letter-spacing: 0.05em;
        line-height: calc(36 / 22);
        @include media-up(lg) {
            margin-top: -7px;
        }
        @include media-down(md) {
            margin-bottom: 10px;
            font-size: calc(18 / 16 * $font-size-base);
            line-height: calc(29 / 18);
        }
    }
    ._menu-price {
        margin-bottom: 7px;
    }
    ._text {
        margin-bottom: 25px;
        font-size: calc(16 / 16 * $font-size-base);
        letter-spacing: 0.05em;
        line-height: calc(26 / 16);
        @include media-down(md) {
            margin-bottom: 20px;
            font-size: calc(13 / 16 * $font-size-base);
            line-height: calc(21 / 13);
        }
    }
    .description {
        margin-top: 25px;
        margin-bottom: 0;
        font-size: calc(15 / 16 * $font-size-base);
        letter-spacing: 0.05em;
        line-height: calc(24 / 15);
        @include media-down(md) {
            margin-top: 18px;
            font-size: calc(12 / 16 * $font-size-base);
            line-height: calc(19 / 12);
        }
    }
}
.menu-pattern-2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 75px 40px;
    margin: 0 0 145px;
    padding: 0;
    list-style-type: none;
    @include media-down(lg) {
        gap: 50px 30px;
    }
    @include media-down(md) {
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 75px;
    }
    @include media-down(sm) {
        gap: 50px;
        grid-template-columns: 1fr;
    }
    li {
        img {
            width: 100%;
            height: auto;
        }
    }
    ._name {
        margin: 25px 0 8px;
        font-size: calc(22 / 16 * $font-size-base);
        font-weight: bold;
        letter-spacing: 0.05em;
        line-height: calc(36 / 22);
        @include media-down(md) {
            margin: 15px 0 8px;
            font-size: calc(18 / 16 * $font-size-base);
        }
    }
    ._menu-price {
        margin-bottom: 17px;
        @include media-down(md) {
        }
    }
    ._tyuki {
        margin: 0;
        font-size: calc(16 / 16 * $font-size-base);
        letter-spacing: 0.05em;
        line-height: calc(26 / 16);
        @include media-down(md) {
            font-size: calc(13 / 16 * $font-size-base);
        }
    }
    ._description {
        margin: 13px 0 0;
        font-size: calc(14 / 16 * $font-size-base);
        letter-spacing: 0.05em;
        line-height: calc(24 / 14);
        @include media-down(md) {
            margin-top: 12px;
            font-size: calc(11 / 16 * $font-size-base);
        }
    }
}
.menu-pattern-3 {
    margin-bottom: 80px;
    @include media-down(md) {
        margin-bottom: 45px;
    }
    h3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        padding: 20px 0 20px 5px;
        border-top: solid 1px #d4d4d4;
        border-bottom: solid 1px #d4d4d4;
        @include media-down(md) {
            margin-bottom: 25px;
            padding-block: 18px;
        }
        ._eng {
            color: #282828;
            @include font-en();
            margin: 0;
            font-size: calc(20 / 16 * $font-size-base);
            letter-spacing: 0;
            @include media-down(md) {
                font-size: calc(16 / 16 * $font-size-base);
            }
        }
        ._ja {
            font-size: calc(15 / 16 * $font-size-base);
            letter-spacing: 0.05em;
            @include media-down(md) {
                font-size: calc(12 / 16 * $font-size-base);
            }
        }
    }
    ._single {
        margin-bottom: 30px;
        @include media-down(md) {
            margin-bottom: 0;
        }
    }
    ._split {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 100px;
        @media (max-width: 1050px) {
            gap: 50px;
        }
        @media (max-width: 980px) {
            display: flex;
            flex-direction: column;
            gap: 40px;
        }
        @include media-down(lg) {
        }
    }
}
.menu-pattern-drink {
    margin: 0;
    padding: 0;
    list-style-type: none;
    &._col2 {
        column-count: 2;
        column-gap: 100px;
        @media (max-width: 1050px) {
            column-gap: 50px;
        }
        @media (max-width: 980px) {
            column-count: inherit;
            column-gap: inherit;
        }
        li {
            &:last-of-type {
                @include media-down(md) {
                    min-height: 90px;
                    padding-bottom: 0;
                }
            }
        }
    }
    li {
        // &:not(:last-of-type) {
        //     margin-bottom: 45px;
        //     @include media-down(md) {
        //         margin-bottom: 30px;
        //     }
        // }
        @include media-up(lg) {
            min-height: 141px;
        }
        @include media-down(lg) {
            min-height: 110px;
            padding-bottom: 20px;
        }
    }
    ._itemname {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
    ._name {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding-left: 25px;
        font-size: calc(18 / 16 * $font-size-base);
        font-weight: bold;
        letter-spacing: 0.05em;
        line-height: calc(36 / 18);
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 15px auto;
        @include media-down(md) {
            padding-left: 20px;
            font-size: calc(15 / 16 * $font-size-base);
            line-height: calc(29 / 15);
            background-size: 13px auto;
        }
        span {
            margin-left: 5px;
            font-size: calc(12 / 16 * $font-size-base);
            font-weight: normal;
            letter-spacing: 0.11em;
            @include media-down(md) {
                margin-left: 4px;
                font-size: calc(10 / 16 * $font-size-base);
            }
        }
        &._green {
            background-image: url(/assets/img/webp/menu/icon-green@2x.webp);
        }
        &._orange {
            background-image: url(/assets/img/webp/menu/icon-orange@2x.webp);
        }
        &._pink {
            background-image: url(/assets/img/webp/menu/icon-pink@2x.webp);
        }
    }
    ._description {
        margin-bottom: 0;
        font-size: calc(15 / 16 * $font-size-base);
        letter-spacing: 0.05em;
        line-height: calc(24 / 15);
        @include media-down(md) {
            font-size: calc(12 / 16 * $font-size-base);
            line-height: calc(19 / 12);
        }
    }
}
.menu-pattern-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 50px 70px;
    margin: 0 0 160px 0;
    padding: 0;
    list-style-type: none;
    @include media-down(lg) {
        grid-template-columns: repeat(3, 1fr);
    }
    @include media-down(md) {
        gap: 40px 33px;
        margin-bottom: 60px;
    }
    @include media-down(sm) {
        grid-template-columns: repeat(2, 1fr);
    }
    ._name {
        margin: 15px 0 10px;
        font-size: calc(16 / 16 * $font-size-base);
        font-weight: bold;
        font-feature-settings: normal;
        letter-spacing: 0.05em;
        line-height: calc(24 / 16);
        @include media-down(md) {
            margin: 10px 0 8px;
            font-size: calc(13 / 16 * $font-size-base);
        }
    }
}
.menu-food {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    margin-bottom: 130px;
    @include media-down(md) {
        gap: 7px;
        margin-bottom: 60px;
    }
    img {
        width: 100%;
        max-width: 960px;
    }
}
.menu-drink {
    display: flex;
    justify-content: center;
    margin-bottom: 135px;
    @include media-down(md) {
        margin-bottom: 60px;
    }
    img {
        width: 100%;
        max-width: 960px;
    }
}
.bnr-powerfood {
    display: flex;
    align-items: center;
    margin-bottom: 100px;
    @include media-down(md) {
        margin-bottom: 70px;
    }
    @include media-up(sm) {
        justify-content: center;
    }
    @include media-down(sm) {
        flex-direction: column;
    }
    img {
        height: auto;
    }
    p {
        margin-bottom: 0;
        font-size: calc(13 / 16 * $font-size-base);
        font-weight: bold;
        letter-spacing: 0.05em;
        line-height: calc(20 / 13);
        @include media-up(sm) {
            margin-left: 5px;
        }
    }
}
