@function svg-right-angle($theme: $primary) {
    @return svgUrlFunc(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$theme}" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>'
    );
}

@function svg-bottom-angle($theme: $body-color) {
    @return svgUrlFunc(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$theme}" d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"/></svg>'
    );
}

.btn-icon-right-angle {
    position: relative;
    &:after {
        position: absolute;
        content: '';
        top: calc(50% - 0.3em);
        right: 1em;
        width: 1em;
        height: 0.6em;
        background: center / contain no-repeat;
        background-image: svg-right-angle();
    }
    &:hover:after,
    &:focus:after {
        background-image: svg-right-angle($white);
    }
}

.btn-icon-right-angle-white {
    @extend .btn-icon-right-angle;
    &:after {
        background-image: svg-right-angle($white);
    }
}

.btn-icon-bottom-angle {
    position: relative;
    &:after {
        position: absolute;
        content: '';
        top: calc(50% - 0.3em);
        right: 0;
        width: 2em;
        height: 0.6em;
        transition: all 0.5s ease-in-out;
        background: center / contain no-repeat;
        background-image: svg-bottom-angle();
        transform: rotateX(180deg);
    }
    &.collapsed:after {
        transform: rotateX(0deg);
    }
}

@mixin c-button-base($align: flex-start) {
    transition: $transition-base;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 306px;
    height: 66px;
    padding-inline: 10px;
    @include font-en();
    font-size: calc(16 / 16 * $font-size-base);
    color: #282828;
    text-decoration: none !important;
    line-height: 1;
    background-color: #fff;
    border: solid 1px #282828;
    @include media-down(md) {
        min-width: 214px;
        height: 46px;
        font-size: calc(12 / 16 * $font-size-base);
    }
    @media (hover: hover) {
        &:hover {
            color: #fff;
            background-color: #282828;
        }
    }
}
@mixin c-button-border($align: flex-start) {
    display: flex;
    align-items: center;
    gap: 30px;
    text-decoration: none !important;
    @include media-down(md) {
        gap: 24px;
    }
    ._text {
        @include font-en();
        margin: 0;
        font-size: calc(16 / 16 * $font-size-base);
        color: #282828;
        @include media-down(md) {
            font-size: calc(12 / 16 * $font-size-base);
        }
    }
    ._icon-circle {
        transition-duration: 0.2s;
        position: relative;
        width: 80px;
        height: 80px;
        border: solid 1px #282828;
        border-radius: 50%;
        @include media-down(md) {
            width: 58px;
            height: 58px;
        }
        &::before,
        &::after {
            content: '';
        }
        &::before {
            opacity: 0;
            transition-duration: 0.3s;
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: solid 1px #282828;
        }
        &::after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 9px;
            height: 9px;
            margin: auto;
            background: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.43 8.43"><defs><style>.cls-1{fill:#282828;stroke-width:0px;}</style></defs><path class="cls-1" d="M0,8.43l8.43-4.22L0,0c.89,1.18,1.43,2.63,1.43,4.22s-.54,3.04-1.43,4.22Z"/></svg>'
                )
                no-repeat left center / 100% auto;
            @include media-down(md) {
                width: 6px;
                height: 6px;
            }
        }
        ._circle {
            width: 100%;
            height: 100%;
            transform: rotate(-90deg);
            fill: none;
            stroke: #282828 !important;
            stroke-width: 1px;
            stroke-dasharray: 251;
            stroke-dashoffset: 251;
        }
    }
    @media (hover: hover) {
        &:hover {
            ._icon-circle {
                transition-duration: 0s;
                border: none;
                &::before {
                    transition-delay: 0.6s;
                    opacity: 0.35;
                }
                &::after {
                    animation: circleBdrIcon 3s $ease-easy infinite;
                }
                ._circle {
                    animation: circleBdrFill 0.6s $ease-easy 0s 1 forwards;
                }
            }
        }
    }
}
@mixin c-button-border-white($align: flex-start) {
    display: flex;
    align-items: center;
    gap: 30px;
    text-decoration: none !important;
    @include media-down(md) {
        gap: 24px;
    }
    ._text {
        @include font-en();
        margin: 0;
        font-size: calc(16 / 16 * $font-size-base);
        color: #fff;
        @include media-down(md) {
            font-size: calc(12 / 16 * $font-size-base);
        }
    }
    ._icon-circle {
        transition-duration: 0.2s;
        position: relative;
        width: 80px;
        height: 80px;
        border: solid 1px #fff;
        border-radius: 50%;
        @include media-down(md) {
            width: 58px;
            height: 58px;
        }
        &::before,
        &::after {
            content: '';
        }
        &::before {
            opacity: 0;
            transition-duration: 0.3s;
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: solid 1px #fff;
        }
        &::after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 9px;
            height: 9px;
            margin: auto;
            background: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.43 8.43"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="M0,8.43l8.43-4.22L0,0c.89,1.18,1.43,2.63,1.43,4.22s-.54,3.04-1.43,4.22Z"/></svg>'
                )
                no-repeat left center / 100% auto;
            @include media-down(md) {
                width: 6px;
                height: 6px;
            }
        }
        ._circle {
            width: 100%;
            height: 100%;
            transform: rotate(-90deg);
            fill: none;
            stroke: #fff;
            stroke-width: 1px;
            stroke-dasharray: 251;
            stroke-dashoffset: 251;
        }
    }
}
@keyframes circleBdrFill {
    0% {
        stroke-dashoffset: 251;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
@keyframes circleBdrIcon {
    0% {
        opacity: 0;
        transform: translateX(-10px);
    }
    25% {
        opacity: 1;
    }
    60% {
        opacity: 1;
        transform: translateX(10px);
    }
    80% {
        opacity: 0;
        transform: translateX(10px);
    }
    100% {
        opacity: 0;
        transform: translateX(10px);
    }
}
@mixin c-button-reserve($align: flex-start) {
    transition: $transition-base;
    display: flex;
    align-items: center;
    width: 368px;
    height: 80px;
    padding-left: 38px;
    font-size: calc(16 / 16 * $font-size-base);
    text-decoration: none !important;
    color: #282828 !important;
    letter-spacing: 0.06em;
    border: solid 1px #282828;
    background: svgUrlFunc(
            '<svg id="design" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.43 8.43"><defs><style>.cls-1{fill:#282828;stroke-width:0px;}</style></defs><path class="cls-1" d="M0,8.43l8.43-4.22L0,0c.89,1.18,1.43,2.63,1.43,4.22s-.54,3.04-1.43,4.22Z"/></svg>'
        )
        no-repeat right 40px center / 9px auto;
    @include media-down(md) {
        width: 257px;
        height: 55px;
        padding-left: 27px;
        font-size: calc(13 / 16 * $font-size-base);
        background-size: 6px auto;
        background-position: right 28px center;
    }
    @include media-down(sm) {
        font-size: calc(10 / 16 * $font-size-base);
    }
    ._icon {
        transition: $transition-base;
        width: 24px;
        height: 24px;
        margin-right: 15px;
        background: svgUrlFunc(
                '<svg id="design" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.1 24.1"><defs><style>.cls-1{fill:#000;stroke-width:0px;}</style></defs><path class="cls-1" d="M12.88,18.84c.13.13.29.19.44.19s.32-.06.44-.19l5.07-5.07c.25-.25.25-.63,0-.89s-.63-.25-.89,0l-5.07,5.07c-.25.25-.25.63,0,.89h0ZM7.8,18.84c.13.13.29.19.44.19s.32-.06.44-.19l5.07-5.07c.25-.25.25-.63,0-.89s-.63-.25-.89,0l-4.63,4.63-2.09-2.09c-.25-.25-.63-.25-.89,0s-.25.63,0,.89l2.54,2.54ZM22.2,1.27h-4.44v-.63c0-.35-.29-.63-.63-.63s-.63.29-.63.63v.63H7.61v-.63c0-.35-.29-.63-.63-.63s-.63.29-.63.63v.63H1.9c-1.05,0-1.9.86-1.9,1.9v19.03c0,1.05.86,1.9,1.9,1.9h20.3c1.05,0,1.9-.86,1.9-1.9V3.17c0-1.05-.86-1.9-1.9-1.9ZM22.84,22.2c0,.35-.29.63-.63.63H1.9c-.35,0-.63-.29-.63-.63v-13.32h21.57v13.32ZM22.84,7.61H1.27V3.17c0-.35.29-.63.63-.63h4.44v1.9c0,.35.29.63.63.63s.63-.29.63-.63v-1.9h8.88v1.9c0,.35.29.63.63.63s.63-.29.63-.63v-1.9h4.44c.35,0,.63.29.63.63v4.44Z"/></svg>'
            )
            no-repeat center / 100% auto;
        @include media-down(md) {
            width: 17px;
            height: 17px;
            margin-right: 10px;
        }
    }
    @media (hover: hover) {
        &:hover {
            color: #fff !important;
            background-image: svgUrlFunc(
                '<svg id="design" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.43 8.43"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="M0,8.43l8.43-4.22L0,0c.89,1.18,1.43,2.63,1.43,4.22s-.54,3.04-1.43,4.22Z"/></svg>'
            );
            background-color: #282828;
            ._icon {
                background-image: svgUrlFunc(
                    '<svg id="design" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.1 24.1"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="M12.88,18.84c.13.13.29.19.44.19s.32-.06.44-.19l5.07-5.07c.25-.25.25-.63,0-.89s-.63-.25-.89,0l-5.07,5.07c-.25.25-.25.63,0,.89h0ZM7.8,18.84c.13.13.29.19.44.19s.32-.06.44-.19l5.07-5.07c.25-.25.25-.63,0-.89s-.63-.25-.89,0l-4.63,4.63-2.09-2.09c-.25-.25-.63-.25-.89,0s-.25.63,0,.89l2.54,2.54ZM22.2,1.27h-4.44v-.63c0-.35-.29-.63-.63-.63s-.63.29-.63.63v.63H7.61v-.63c0-.35-.29-.63-.63-.63s-.63.29-.63.63v.63H1.9c-1.05,0-1.9.86-1.9,1.9v19.03c0,1.05.86,1.9,1.9,1.9h20.3c1.05,0,1.9-.86,1.9-1.9V3.17c0-1.05-.86-1.9-1.9-1.9ZM22.84,22.2c0,.35-.29.63-.63.63H1.9c-.35,0-.63-.29-.63-.63v-13.32h21.57v13.32ZM22.84,7.61H1.27V3.17c0-.35.29-.63.63-.63h4.44v1.9c0,.35.29.63.63.63s.63-.29.63-.63v-1.9h8.88v1.9c0,.35.29.63.63.63s.63-.29.63-.63v-1.9h4.44c.35,0,.63.29.63.63v4.44Z"/></svg>'
                );
            }
        }
    }
}
@mixin c-button-reserve-store($align: flex-start) {
    transition: $transition-base;
    display: flex;
    align-items: center;
    width: 306px;
    height: 66px;
    padding-left: 27px;
    font-size: calc(16 / 16 * $font-size-base);
    text-decoration: none !important;
    color: #282828 !important;
    letter-spacing: 0.06em;
    border: solid 1px #282828;
    background: svgUrlFunc(
            '<svg id="design" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.43 8.43"><defs><style>.cls-1{fill:#282828;stroke-width:0px;}</style></defs><path class="cls-1" d="M0,8.43l8.43-4.22L0,0c.89,1.18,1.43,2.63,1.43,4.22s-.54,3.04-1.43,4.22Z"/></svg>'
        )
        no-repeat right 22px center / 9px auto;
    @include media-down(md) {
        width: 245px;
        height: 53px;
        padding-left: 21px;
        font-size: calc(13 / 16 * $font-size-base);
        background-size: 7px auto;
        background-position: right 19px center;
    }
    @include media-down(sm) {
        font-size: calc(11 / 16 * $font-size-base);
    }
    ._icon {
        transition: $transition-base;
        width: 27px;
        height: 27px;
        margin-right: 20px;
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.11 25.22"><defs><style>.cls-1{fill:#282828;stroke-width:0px;}</style></defs><path class="cls-1" d="M21.67,20.5c-1.3,0-2.36,1.06-2.36,2.36s1.06,2.36,2.36,2.36,2.36-1.06,2.36-2.36-1.06-2.36-2.36-2.36ZM21.67,23.93c-.59,0-1.07-.48-1.07-1.07s.48-1.07,1.07-1.07,1.07.48,1.07,1.07c0,.59-.48,1.07-1.07,1.07ZM10.79,20.5c-1.3,0-2.36,1.06-2.36,2.36s1.06,2.36,2.36,2.36,2.36-1.06,2.36-2.36-1.06-2.36-2.36-2.36ZM10.79,23.93c-.59,0-1.07-.48-1.07-1.07s.48-1.07,1.07-1.07,1.07.48,1.07,1.07-.48,1.07-1.07,1.07ZM26.99,4.75c-.12-.18-.32-.28-.53-.28H6.15l-.84-3.95c-.06-.3-.33-.51-.63-.51H.65c-.36,0-.65.29-.65.65s.29.65.65.65h3.51l3.44,16.14c.2,1.38,1.38,2.44,2.82,2.44h12.22c.36,0,.65-.29.65-.65s-.29-.65-.65-.65h-12.22c-.86,0-1.56-.7-1.56-1.56s.7-1.56,1.56-1.56h12.22c.27,0,.5-.16.6-.41l3.82-9.72c.08-.2.05-.42-.07-.6ZM22.2,14.19h-11.78c-.78,0-1.5.32-2.01.83l-1.97-9.27h19.09l-3.32,8.43ZM11.91,11.88v-3.86c0-.36.29-.65.65-.65s.65.29.65.65v3.86c0,.36-.29.65-.65.65s-.65-.29-.65-.65ZM15.41,11.88v-3.86c0-.36.29-.65.65-.65s.65.29.65.65v3.86c0,.36-.29.65-.65.65s-.65-.29-.65-.65ZM18.9,11.88v-3.86c0-.36.29-.65.65-.65s.65.29.65.65v3.86c0,.36-.29.65-.65.65s-.65-.29-.65-.65Z"/></svg>'
            )
            no-repeat center / 100% auto;
        @include media-down(md) {
            width: 22px;
            height: 21px;
            margin-right: 18px;
        }
    }
    @media (hover: hover) {
        &:hover {
            color: #fff !important;
            background-image: svgUrlFunc(
                '<svg id="design" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.43 8.43"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="M0,8.43l8.43-4.22L0,0c.89,1.18,1.43,2.63,1.43,4.22s-.54,3.04-1.43,4.22Z"/></svg>'
            );
            background-color: #282828;
            ._icon {
                background-image: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.11 25.22"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="M21.67,20.5c-1.3,0-2.36,1.06-2.36,2.36s1.06,2.36,2.36,2.36,2.36-1.06,2.36-2.36-1.06-2.36-2.36-2.36ZM21.67,23.93c-.59,0-1.07-.48-1.07-1.07s.48-1.07,1.07-1.07,1.07.48,1.07,1.07c0,.59-.48,1.07-1.07,1.07ZM10.79,20.5c-1.3,0-2.36,1.06-2.36,2.36s1.06,2.36,2.36,2.36,2.36-1.06,2.36-2.36-1.06-2.36-2.36-2.36ZM10.79,23.93c-.59,0-1.07-.48-1.07-1.07s.48-1.07,1.07-1.07,1.07.48,1.07,1.07-.48,1.07-1.07,1.07ZM26.99,4.75c-.12-.18-.32-.28-.53-.28H6.15l-.84-3.95c-.06-.3-.33-.51-.63-.51H.65c-.36,0-.65.29-.65.65s.29.65.65.65h3.51l3.44,16.14c.2,1.38,1.38,2.44,2.82,2.44h12.22c.36,0,.65-.29.65-.65s-.29-.65-.65-.65h-12.22c-.86,0-1.56-.7-1.56-1.56s.7-1.56,1.56-1.56h12.22c.27,0,.5-.16.6-.41l3.82-9.72c.08-.2.05-.42-.07-.6ZM22.2,14.19h-11.78c-.78,0-1.5.32-2.01.83l-1.97-9.27h19.09l-3.32,8.43ZM11.91,11.88v-3.86c0-.36.29-.65.65-.65s.65.29.65.65v3.86c0,.36-.29.65-.65.65s-.65-.29-.65-.65ZM15.41,11.88v-3.86c0-.36.29-.65.65-.65s.65.29.65.65v3.86c0,.36-.29.65-.65.65s-.65-.29-.65-.65ZM18.9,11.88v-3.86c0-.36.29-.65.65-.65s.65.29.65.65v3.86c0,.36-.29.65-.65.65s-.65-.29-.65-.65Z"/></svg>'
                );
            }
        }
    }
}
@mixin c-button-arrow() {
    transition: $transition-base;
    display: block;
    width: 185px;
    margin: auto;
    padding: 5px 0 13px 5px;
    font-size: calc(14 / 16 * $font-size-base);
    text-decoration: none !important;
    color: #282828 !important;
    letter-spacing: 0.06em;
    line-height: 1;
    border-bottom: solid 1px #282828;
    background: svgUrlFunc(
            '<svg id="design" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.43 8.43"><defs><style>.cls-1{fill:#282828;stroke-width:0px;}</style></defs><path class="cls-1" d="M0,8.43l8.43-4.22L0,0c.89,1.18,1.43,2.63,1.43,4.22s-.54,3.04-1.43,4.22Z"/></svg>'
        )
        no-repeat right top 8px / 9px auto;
    @include media-down(md) {
        width: 148px;
        padding-bottom: 10px;
        font-size: calc(11 / 16 * $font-size-base);
        background-size: 7px auto !important;
    }
    @media (hover: hover) {
        &:hover {
            color: $primary !important;
            border-bottom-color: $primary;
            background: svgUrlFunc(
                    '<svg id="design" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.43 8.43"><defs><style>.cls-1{fill:#bcaf90;stroke-width:0px;}</style></defs><path class="cls-1" d="M0,8.43l8.43-4.22L0,0c.89,1.18,1.43,2.63,1.43,4.22s-.54,3.04-1.43,4.22Z"/></svg>'
                )
                no-repeat right top 8px / 9px auto;
        }
    }
}
