@mixin c-module-title() {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.75em;
  font-weight: normal;
  letter-spacing: 0.22em;
  text-indent: 0.22em;
  font-size: $h2-font-size;
  @include media-down(lg) {
    font-size: 1.875rem;
  }
  @include media-down(md) {
    font-size: $h4-font-size;
  }

  span {
    font-family: "Roboto", $font-family-sans-serif;
    font-weight: $font-weight-normal;
  }

  &:after{
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: block;
    width: 82px;
    height: 1px;
    margin: auto;
    background: $primary;
  }
}

@mixin font-en() {
	font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

@mixin c-block-title($align: flex-start) {
    position: relative;
    display: flex;
    flex-direction: column;
    @if $align == 'center' {
        align-items: center;
    }
    @include font-en();
    font-size: calc(35 / 16 * $font-size-base);
    color: #282828;
    line-height: 1.3;
    @include media-down(md) {
      font-size: calc(26 / 16 * $font-size-base);
    }
}