.rwd002-moduletitle {
    margin-top: map-get($spacers, 6);
    margin-bottom: map-get($spacers, 4);
    text-align: center;
    @include media-down(md) {
        margin-top: map-get($spacers, 4);
        margin-bottom: map-get($spacers, 4);
    }
    &__inner {
        @include c-module-title;
        padding-left: 1.6875rem;
        padding-right: 1.6875rem;
    }
}

.moduletitle {
    .is--menu & {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: calc((100% - 150px) / 2 + 150px);
            background-color: #e1ded3;
        }
    }
    ._title {
        @include font-en();
        margin: 0;
        padding: 54px 0;
        // padding: 45px 0 60px;
        font-size: calc(35 / 16 * $font-size-base);
        text-align: center;
        @include media-down(md) {
            // padding: 35px 0 25px;
            padding: 28px 0;
            font-size: calc(22 / 16 * $font-size-base);
        }
    }
    &__headline {
        position: relative;
        @include media-up(lg) {
            padding-inline: 100px;
        }
        img {
            width: 100%;
            height: auto;
        }
        &__title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            margin: 0;
            color: #fff;
            line-height: 1;
            ._ja {
                margin-top: 5px;
                font-size: calc(16 / 16 * $font-size-base);
                letter-spacing: 0.15em;
                @include media-down(md) {
                  margin-top: 4px;
                  font-size: calc(12 / 16 * $font-size-base);
                }
            }
            ._eng {
                @include font-en();
                font-size: calc(50 / 16 * $font-size-base);
                @include media-down(md) {
                  font-size: calc(35 / 16 * $font-size-base);
                }
            }
        }
    }
}
