.rwd002-container {
  margin-top: map-get($spacers, 6);
  @include media-down(lg) {
    margin-top: map-get($spacers, 3);
  }
  .is--news.is--list &,
  .is--event.is--list & {
    margin-top: 0;
  }
  .rwd002-privacy {
    margin-bottom: 90px;
    @include media-down(lg) {
      margin-bottom: 50px;
    }
  }
  .is--contact & {
    margin-bottom: 90px;
    @include media-down(lg) {
      margin-bottom: 50px;
    }
  }
}