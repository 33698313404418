.is--event.is--list {
    .rwd002-list-content {
        &__list {
            width: 100%;
            max-width: 1090px;
            margin-inline: auto;
        }
        &__item {
            &:not(:first-child) {
                margin-top: 105px;
                @include media-down(md) {
                    margin-top: 55px;
                }
            }
        }
        &__link {
            @include media-down(md) {
                flex-direction: column;
            }
        }
        &__thumb {
            width: 56.8%;
            aspect-ratio: 620 / 410;
            margin-right: 65px;
            @include media-down(lg) {
                margin-right: 35px;
            }
            @include media-down(md) {
                width: 100%;
            }
        }
        &__body {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .cate {
                order: 2;
                margin-bottom: 17px;
                border-radius: 0;
                @include media-down(md) {
                    margin-bottom: 15px;
                }
                @include media-down(sm) {
                    font-size: calc(10 / 16 * $font-size-base);
                }
            }
            .title {
                order: 1;
                margin-bottom: 15px;
                font-size: calc(22 / 16 * $font-size-base);
                font-weight: normal;
                letter-spacing: 0.1em;
                @include media-down(md) {
                    margin-top: 20px;
                    margin-bottom: 13px;
                }
                @include media-down(sm) {
                    font-size: calc(18 / 16 * $font-size-base);
                }
            }
            .desc {
                order: 3;
                margin-bottom: 25px;
                font-size: calc(15 / 16 * $font-size-base);
                font-weight: normal;
                letter-spacing: 0.06em;
                @include media-down(sm) {
                    font-size: calc(12 / 16 * $font-size-base);
                }
            }
            .body {
                order: 4;
                font-size: calc(15 / 16 * $font-size-base);
                color: #7e7e7e;
                letter-spacing: 0.06em;
                line-height: calc(25 / 15);
                @include media-down(sm) {
                    font-size: calc(12 / 16 * $font-size-base);
                }
            }
        }
    }
}
.is--event.is--detail {
    .rwd002-block.is-head {
        display: none;
    }
    .rwd002-container {
        margin-top: 15px;
        @include media-down(md) {
            margin-top: 2px;
        }
    }
    .rwd002-detail {
        margin-top: 0;
        padding: 0;
        &__inner {
            padding-inline: map-get($spacers, 3);
        }
    }
    .rwd002-detail__entry {
        max-width: 1100px;
    }
    .rwd002-detail__image {
        max-width: 960px;
        margin: 0 auto 90px;
        @include media-down(md) {
            margin-bottom: 60px;
        }
    }
    .rwd002-detail__title {
        margin-bottom: 18px;
        padding-bottom: 15px;
        font-size: calc(30 / 16 * $font-size-base);
        font-weight: normal;
        text-align: center;
        letter-spacing: 0.2em;
        line-height: calc(34 / 24);
        border-bottom-color: #332a23;
        @include media-down(md) {
            margin-bottom: 16px;
            padding-bottom: 13px;
            font-size: calc(21 / 16 * $font-size-base);
        }
    }
    .rwd002-detail__desc {
        margin: 0 0 75px;
        font-size: calc(16 / 16 * $font-size-base);
        text-align: center;
        letter-spacing: 0.06em;
        @include media-down(md) {
            margin-bottom: 60px;
            font-size: calc(13 / 16 * $font-size-base);
        }
    }
    .post-entry {
        width: 100%;
        max-width: 720px;
        margin-inline: auto;
        @include media-down(md) {
            padding-inline: map-get($spacers, 3);
        }
        p {
            margin-bottom: 35px;
            font-feature-settings: normal;
            line-height: calc(35 / 16);
            @include media-down(md) {
                margin-bottom: 25px;
                font-size: calc(13 / 16 * $font-size-base);
            }
        }
        table {
            @include table-typeA();
            margin: 80px 0 90px;
            @include media-down(md) {
                margin: 70px 0;
            }
        }
    }
}
.event-btnwrap {
    display: flex;
    justify-content: center;
}
.event-btn {
    @include c-button-reserve();
}
