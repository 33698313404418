.is--home {
    .rwd002-container {
        margin: 0;
        padding: 0;
    }
    .footer-fixbnr {
        display: block;
        &.is-close {
            display: none;
        }
    }
    .rwd002-pagetop__wrap {
        bottom: 220px;
        &.is-bnrClose {
            bottom: 20px;
        }
    }
    .rwd002-block-freearea {
        margin: 0 !important;
        padding: 0 !important;
        &__inner {
            max-width: inherit;
            margin: 0;
        }
    }
    .rwd002-pagetop {
    }
}
.mainvisual {
    position: relative;
    display: flex;
    padding-bottom: 145px;
    @include media-down(lg) {
        flex-direction: column-reverse;
        padding: 0;
        background-color: #e1e1e1;
    }
    @include media-up(lg) {
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 67%;
            background-color: #e1e1e1;
        }
    }
    &__head {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include media-up(lg) {
            width: 20%;
        }
        @include media-down(lg) {
            width: 100%;
            padding: 25px 0 33px;
        }
        &__lead {
            font-size: calc(20 / 16 * $font-size-base);
            letter-spacing: 0.2em;
            line-height: calc(33 / 20);
            @include media-up(lg) {
                transform: translateY(-13px);
                margin: 0 28% 0 0;
                -ms-writing-mode: tb-rl;
                writing-mode: vertical-rl;
            }
            @include media-down(lg) {
                width: fit-content;
                margin: 0 auto;
            }
            @include media-down(sm) {
                font-size: calc(16 / 16 * $font-size-base);
                line-height: calc(26 / 16);
            }
        }
    }
    &__scroll {
        position: absolute;
        bottom: 0;
        left: 18%;
        display: flex;
        align-items: center;
        writing-mode: vertical-rl;
        column-gap: 20px;
        @include font-en();
        font-size: calc(12 / 16 * $font-size-base);
        color: #282828;
        line-height: 1;
        z-index: 1;
        @include media-down(lg) {
            display: none;
        }
        .bar {
            position: relative;
            overflow: hidden;
            width: 1px;
            height: 98px;
            background-color: #fff;
            &::before {
            }
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 1px;
                height: 98px;
                margin: auto;
                background: #282828;
                // transform: translateY(-25px);
                animation: scroll_line 2.3s cubic-bezier(1, 0, 0, 1) infinite;
            }
        }
    }
    &__slider-pager {
        --swiper-theme-color: #{$primary};
        --swiper-pagination-bullet-vertical-gap: 1em;
        --swiper-pagination-bullet-size: 6px;
        --swiper-pagination-bullet-inactive-color: #{$white};
        --swiper-pagination-bullet-inactive-opacity: 0.6;
        position: absolute;
        top: inherit !important;
        right: 0 !important;
        bottom: 105px !important;
        left: inherit !important;
        z-index: 9;
        display: flex;
        justify-content: center;
        transform: none !important;
        margin-right: 0;
        @include media-up(lg) {
            width: 80%;
        }
        @include media-down(lg) {
            width: 100%;
            bottom: 0 !important;
        }
        &.swiper-pagination-vertical {
            @include media-up(lg) {
            }
            @include media-down(lg) {
            }
        }
        .swiper-pagination-bullet {
            position: relative;
            opacity: 1;
            width: 7px;
            height: 7px;
            margin: 0 8px !important;
            background: #d4d4d4;
        }
        .swiper-pagination-bullet-active {
            background-color: #282828;
        }
    }
}
@keyframes scroll_line {
    0% {
        transform: scale(1, 0);
        transform-origin: 0 0;
    }
    50% {
        transform: scale(1, 1);
        transform-origin: 0 0;
    }
    50.1% {
        transform: scale(1, 1);
        transform-origin: 0 100%;
    }
    100% {
        transform: scale(1, 0);
        transform-origin: 0 100%;
    }
}
.mainvisual-slider__wrapper {
    overflow: hidden;
    @include media-up(lg) {
        width: 80%;
    }
    @include media-down(lg) {
        position: relative;
        width: 100%;
        padding-bottom: 30px;
    }
}
.mainvisual-slider {
    aspect-ratio: 1120 / 560;
    width: 100%;
    &__item img {
        width: 100%;
        height: auto;
    }
}
.block-news {
    position: relative;
    margin-top: -1px;
    margin-bottom: 115px;
    @include media-down(md) {
        margin-bottom: 35px;
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 36%;
        background-color: #e1e1e1;
    }
    &__title {
        margin-bottom: 35px;
        @include c-block-title();
        @include media-down(md) {
            margin-bottom: 25px;
        }
    }
    .swiper-area {
        position: relative;
    }
    .news-slider {
        overflow: hidden;
        margin-left: 150px;
        @include media-down(lg) {
            margin-left: map-get($spacers, 3);
        }
        &__btn-wrap {
            position: absolute;
            top: 5px;
            right: 0;
            left: 0;
            padding-inline: map-get($spacers, 3);
            @include media-down(md) {
                top: 0;
            }
            &__inner {
                display: flex;
                justify-content: flex-end;
                gap: 14px;
                width: 100%;
                max-width: 1100px;
                margin-inline: auto;
                @include media-down(md) {
                    gap: 11px;
                }
            }
        }
    }
    .swiper-button {
        &-prev,
        &-next {
            transition: $transition-base;
            position: relative;
            top: inherit;
            left: inherit;
            right: inherit;
            width: 40px;
            height: 40px;
            margin: 0;
            border-radius: 50%;
            border: solid 1px #282828;
            @include media-down(md) {
                width: 32px;
                height: 32px;
            }
            &::after {
                position: absolute;
                content: '';
                width: 9px;
                height: 9px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.43 8.43"><defs><style>.cls-1{fill:#282828;stroke-width:0px;}</style></defs><path class="cls-1" d="M0,8.43l8.43-4.22L0,0c.89,1.18,1.43,2.63,1.43,4.22s-.54,3.04-1.43,4.22Z"/></svg>'
                    )
                    no-repeat center / 100% auto;
                @include media-down(md) {
                    width: 7px;
                    height: 7px;
                }
            }
            @media (hover: hover) {
                &:hover {
                    background-color: #282828;
                    &::after {
                        background: svgUrlFunc(
                                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.43 8.43"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="M0,8.43l8.43-4.22L0,0c.89,1.18,1.43,2.63,1.43,4.22s-.54,3.04-1.43,4.22Z"/></svg>'
                            )
                            no-repeat center / 100% auto;
                    }
                }
            }
        }
        &-prev {
            &::after {
                transform: translate(-50%, -50%) rotate(180deg);
            }
        }
        &-next {
            @include media-down(sm) {
                // top: 34%;
                // left: map-get($spacers, 5);
                // width: 44px !important;
                // height: 44px !important;
            }
        }
    }
}
.block-news-item {
    @include media-up(lg) {
        width: 23.2%;
    }
    @include media-down(lg) {
        width: 232px;
    }
    @include media-down(sm) {
    }
    &__link {
        text-decoration: none !important;
        @media (hover: hover) {
            &:hover {
                .block-news-item__img {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
    &__img {
        overflow: hidden;
        aspect-ratio: 290 / 190;
        img {
            transition: $transition-base;
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }
    .date-cate {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 15px 0 12px;
        line-height: 1;
        @include media-down(md) {
            margin: 11px 0 8px;
        }
    }
    .date {
        @include font-en();
        font-size: calc(15 / 16 * $font-size-base);
        color: #7e7e7e;
        @include media-down(md) {
            font-size: calc(12 / 16 * $font-size-base);
        }
    }
    .cate {
        min-width: 75px;
        padding: 3px 7px;
        font-size: calc(12 / 16 * $font-size-base);
        text-align: center;
        color: #7e7e7e;
        letter-spacing: 0.1em;
        border: solid 1px #7e7e7e;
        @include media-down(md) {
            min-width: 60px;
            font-size: calc(10 / 16 * $font-size-base);
        }
    }
    .title {
        margin: 0;
        font-size: calc(14 / 16 * $font-size-base);
        color: #282828;
        letter-spacing: 0.06em;
        line-height: calc(24 / 14);
        @include media-down(sm) {
            font-size: calc(11 / 16 * $font-size-base);
            line-height: calc(19 / 11);
        }
    }
}
.block-news__btn-wrap {
    margin-top: 45px;
    padding-inline: map-get($spacers, 3);
    @include media-down(md) {
        margin-top: 35px;
    }
    &__inner {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        max-width: 1100px;
        margin-inline: auto;
    }
}
.block-news__btn {
    @include c-button-border();
}
.block-concept {
    aspect-ratio: 1400 / 500;
    width: 100%;
    background-attachment: fixed;
    background-image: url(/assets/img/webp/home/back-concept@2x.webp);
    background-position: center;
    background-size: cover;
    @media (max-width: 1200px) {
        background-attachment: inherit;
    }
    @include media-up(sm) {
        min-height: 500px;
    }
    @include media-down(sm) {
        height: 268px;
        background-image: url(/assets/img/webp/home/back-concept_sp@2x.webp);
    }
    a {
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none !important;
        @media (hover: hover) {
            &:hover {
                ._icon-circle {
                    transition-duration: 0s;
                    border: none;
                    &::before {
                        transition-delay: 0.6s;
                        opacity: 0.35;
                    }
                    &::after {
                        animation: circleBdrIcon 3s $ease-easy infinite;
                    }
                    ._circle {
                        animation: circleBdrFill 0.6s $ease-easy 0s 1 forwards;
                    }
                }
            }
        }
    }
    &__inner {
        width: 100%;
        max-width: 1100px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-inline: auto;
        padding: 160px map-get($spacers, 3) 50px;
        @include media-down(sm) {
            padding-top: 85px;
            padding-bottom: 18px;
        }
    }
    &__title {
        @include c-block-title();
        margin-bottom: 35px;
        color: #fff;
        @include media-down(sm) {
            margin-bottom: 10px;
        }
    }
    &__lead {
        margin: 0;
        font-size: calc(22 / 16 * $font-size-base);
        font-family: $font-family-serif;
        font-feature-settings: 'palt';
        color: #fff;
        letter-spacing: 0.25em;
        line-height: calc(24 / 12);
        @include media-down(md) {
            font-size: calc(18 / 16 * $font-size-base);
        }
        @include media-down(sm) {
            font-size: calc(12 / 16 * $font-size-base);
        }
    }
    &__btn-wrap {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: auto;
    }
    &__btn {
        @include c-button-border-white();
    }
}
.block-menu {
    position: relative;
    overflow: hidden;
    margin-bottom: 160px;
    @include media-down(md) {
        margin-bottom: 45px;
    }
    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #e3e0d5;
        @include media-up(md) {
            height: calc(100% - 160px);
        }
        @include media-down(md) {
            height: calc(100% - 87px);
        }
    }
    &__title {
        @include c-block-title();
        display: flex;
        justify-content: center;
        flex-direction: inherit;
        gap: 190px;
        font-size: calc(95 / 16 * $font-size-base);
        margin-bottom: 60px;
        text-align: center;
        color: #fff;
        line-height: 0.7;
        @include media-down(lg) {
            gap: 110px;
            font-size: calc(65 / 16 * $font-size-base);
        }
        @include media-down(md) {
            gap: 85px;
            font-size: calc(45 / 16 * $font-size-base);
        }
        @include media-down(sm) {
            gap: 73px;
            margin-bottom: 45px;
            font-size: calc(35 / 16 * $font-size-base);
        }
    }
    &__lead {
        margin-bottom: 25px;
        font-size: calc(16 / 16 * $font-size-base);
        text-align: center;
        letter-spacing: 0.06em;
        line-height: calc(28 / 16);
        @include media-down(md) {
            font-size: calc(13 / 16 * $font-size-base);
            line-height: calc(23 / 13);
        }
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 25px;
        width: 100%;
        max-width: 850px;
        margin-top: 60px;
        margin-inline: auto;
        @include media-down(sm) {
            gap: 10px;
            margin-top: 40px;
        }
    }
    &__item {
        display: block;
        text-decoration: none !important;
        @media (hover: hover) {
            @include hoverOpacity;
            &:hover &__image {
                img {
                    transform: scale(1.1);
                }
            }
        }
        &__image {
            overflow: hidden;
            aspect-ratio: 267 / 316;
            margin-bottom: 20px;
            @include media-down(md) {
                margin-bottom: 10px;
            }
            img {
                transition: $transition-base;
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
            }
        }
        &__title {
            @include font-en();
            margin: 0;
            font-size: calc(20 / 16 * $font-size-base);
            text-align: center;
            color: #282828;
            @include media-down(md) {
                font-size: calc(15 / 16 * $font-size-base);
            }
        }
    }
}
.block-event {
    margin-bottom: 120px;
    padding-inline: map-get($spacers, 3);
    @include media-down(md) {
        margin-bottom: 45px;
    }
    &__inner {
        width: 100%;
        max-width: 1100px;
        margin-inline: auto;
        @include media-up(md) {
            display: flex;
        }
    }
    &__head {
        @include media-up(md) {
            width: 25%;
        }
        @include media-down(md) {
            width: 100%;
        }
    }
    &__title {
        @include c-block-title();
        margin-bottom: 35px;
        line-height: 1;
        @include media-down(md) {
            margin-bottom: 25px;
        }
    }
    &__content {
        @include media-up(md) {
            width: 75%;
        }
        @include media-down(md) {
            width: 100%;
        }
    }
    &__btn-wrap {
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;
    }
    &__btn {
        @include c-button-border();
    }
}
.block-event__categories-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    @include media-down(md) {
        display: flex;
        gap: 11px;
        margin-bottom: 30px;
    }
}
.block-event__categories-item {
    &:not(:last-of-type) {
        @include media-up(md) {
            margin-bottom: 7px;
        }
    }
    a {
        transition: $transition-base;
        display: inline-block;
        padding: 8px 15px 8px 10px;
        font-size: calc(13 / 16 * $font-size-base);
        text-decoration: none !important;
        color: #282828;
        letter-spacing: 0.1em;
        line-height: 1;
        @include media-down(sm) {
            font-size: calc(11 / 16 * $font-size-base);
        }
        @media (hover: hover) {
            &:hover {
                background-color: #e1e1e1;
            }
        }
    }
    &.is-all {
        a {
            background-color: #e1e1e1;
        }
    }
}
.block-event__list {
}
.block-event-item {
    border-bottom: solid 1px #d4d4d4;
    &:first-of-type {
        border-top: solid 1px #d4d4d4;
    }
    &__link {
        position: relative;
        min-height: 275px;
        display: block;
        padding: 40px 240px 40px 0;
        text-decoration: none !important;
        @include media-down(md) {
            padding-right: 210px;
        }
        @include media-down(sm) {
            min-height: inherit;
            padding: 30px 0;
        }
        @media (hover: hover) {
            &:hover {
                .block-event-item__img {
                    img {
                        transform: scale(1.1);
                    }
                }
                .title.is-new:after {
                    // color: inherit;
                }
            }
        }
    }
    .title {
        margin: 0 0 5px;
        font-size: calc(18 / 16 * $font-size-base);
        font-weight: bold;
        color: #282828;
        letter-spacing: 0.06em;
        line-height: calc(24 / 18);
        @include media-down(md) {
            font-size: calc(14 / 16 * $font-size-base);
            line-height: calc(19 / 14);
        }
        &.is-new:after {
            position: relative;
            top: -3px;
            display: inline-block;
            content: 'NEW';
            display: inline-block;
            width: 50px;
            padding: 2px 0;
            text-align: center;
            color: #fff;
            @include font-en();
            margin-left: 15px;
            font-size: calc(12 / 16 * $font-size-base);
            letter-spacing: 0;
            background-color: $red;
            border-radius: 3px;
            @include media-down(sm) {
                top: -2px;
                width: 40px;
                padding: 1px 0;
                font-size: calc(10 / 16 * $font-size-base);
            }
        }
    }
    .cate {
        min-width: 102px;
        display: inline-block;
        padding: 0 4px;
        font-size: calc(12 / 16 * $font-size-base);
        font-feature-settings: "palt";
        text-align: center;
        color: #7e7e7e;
        letter-spacing: 0.1em;
        border: solid 1px #7e7e7e;
        @include media-down(md) {
            min-width: 81px;
            font-size: calc(10 / 16 * $font-size-base);
        }
    }
    .desc {
        margin: 18px 0 16px;
        font-size: calc(15 / 16 * $font-size-base);
        letter-spacing: 0.06em;
        @include media-down(md) {
            margin: 13px 0 12px;
            font-size: calc(12 / 16 * $font-size-base);
        }
    }
    &__body {
        @include media-down(sm) {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
        p {
            margin: 0;
            font-size: calc(15 / 16 * $font-size-base);
            color: #7e7e7e;
            letter-spacing: 0.06em;
            line-height: calc(25 / 15);
            @include media-down(md) {
                font-size: calc(12 / 16 * $font-size-base);
                line-height: calc(20 / 12);
            }
        }
    }
    &__img {
        aspect-ratio: 1 / 1;
        overflow: hidden;
        @include media-up(sm) {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 192px;
            margin: auto;
        }
        @include media-down(sm) {
            flex-shrink: 0;
            width: 100px;
            margin-left: 25px;
        }
        img {
            transition: $transition-base;
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }
}
.block-shop {
    &__loop {
        width: 100%;
        height: 500px;
        background-image: url(/assets/img/webp/home/pho-shop@2x.webp);
        background-repeat: repeat-x;
        background-size: auto 500px;
        animation: loop 90s linear infinite;
        @include media-down(md) {
            height: 210px;
            background-size: auto 100%;
            animation: loop 100s linear infinite;
        }
    }
    &__btn-wrap {
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: -19px;
    }
    &__btn {
        @include c-button-base();
    }
}
@keyframes loop {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -6380px 0;
    }
}
.bnr-novelty {
    margin-block: 150px;
    padding-inline: map-get($spacers, 3);
    @include media-down(md) {
        margin-block: 60px;
    }
    a {
        display: block;
        margin-inline: auto;
        max-width: 700px;
        width: 100%;
        img {
            width: 100%;
            height: auto;
        }
        @media (hover: hover) {
            @include hoverOpacity;
        }
    }
}
.block-onlinestore {
    aspect-ratio: 1400 / 440;
    width: 100%;
    background-attachment: fixed;
    background-image: url(/assets/img/webp/home/back-onlinestore@2x.webp);
    background-position: center;
    background-size: cover;
    @media (max-width: 1200px) {
        background-attachment: inherit;
    }
    @include media-up(sm) {
        min-height: 440px;
    }
    @include media-down(sm) {
        height: 220px;
        background-image: url(/assets/img/webp/home/back-onlinestore_sp@2x.webp);
    }
    a {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none !important;
        @media (hover: hover) {
            &:hover {
                @include hoverOpacity;
            }
        }
    }
    &__subtitle {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        font-size: calc(20 / 16 * $font-size-base);
        color: #fff;
        @include media-down(md) {
            font-size: calc(16 / 16 * $font-size-base);
        }
        @include media-down(sm) {
            font-size: calc(11 / 16 * $font-size-base);
        }
        ._icon-cart {
            margin-bottom: 24px;
            @include media-down(md) {
                width: 25px;
                height: auto;
                margin-bottom: 12px;
            }
        }
    }
    &__title {
        @include font-en();
        margin-top: 10px;
        margin-bottom: 55px;
        font-size: calc(58 / 16 * $font-size-base);
        margin-bottom: 35px;
        color: #fff;
        letter-spacing: 0.25em;
        @include media-down(md) {
            font-size: calc(29 / 16 * $font-size-base);
            margin-bottom: 30px;
        }
    }
    &__link {
        width: fit-content;
        margin: 0 auto;
        padding-right: 33px;
        @include font-en();
        font-size: calc(16 / 16 * $font-size-base);
        color: #fff;
        letter-spacing: 0.06em;
        background: svgUrlFunc(
                '<svg id="icon-external" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.63 12.63"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><g id="_レイアウト"><polygon class="cls-1" points=".49 2.21 0 2.21 0 12.63 10.42 12.63 10.42 12.14 .49 12.14 .49 2.21"/><path class="cls-1" d="M2.21,0v10.42h10.42V0H2.21ZM12.14,9.93H2.7V.49h9.44v9.44Z"/><polygon class="cls-1" points="5.14 7.83 9.74 3.23 9.74 6.35 10.23 6.35 10.23 2.41 6.28 2.41 6.28 2.89 9.4 2.89 4.8 7.49 5.14 7.83"/></g></svg>'
            )
            no-repeat top 6px right / 13px auto;
        @include media-down(md) {
            padding-right: 18px;
            font-size: calc(10 / 16 * $font-size-base);
            background-size: 7px auto;
        }
    }
}
.block-newitem {
    position: relative;
    margin-bottom: 105px;
    padding-top: 75px;
    padding-bottom: 60px;
    @include media-down(md) {
        padding-top: 50px;
        padding-bottom: 40px;
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: calc(100% - 220px);
        height: 100%;
        background-color: #e1e1e1;
        @include media-down(lg) {
            width: calc(100% - 80px);
        }
    }
    &__title {
        margin-bottom: 35px;
        @include c-block-title();
        @include media-down(md) {
            margin-bottom: 25px;
        }
    }
    .swiper-area {
        position: relative;
    }
    .newitem-slider {
        overflow: hidden;
        margin-left: 150px;
        @include media-down(lg) {
            margin-left: map-get($spacers, 3);
        }
        &__btn-wrap {
            position: absolute;
            top: 5px;
            right: 0;
            left: 0;
            padding-inline: map-get($spacers, 3);
            @include media-down(md) {
                top: 0;
            }
            &__inner {
                display: flex;
                justify-content: flex-end;
                gap: 14px;
                width: 100%;
                max-width: 1100px;
                margin-inline: auto;
                @include media-down(md) {
                    gap: 11px;
                }
            }
        }
    }
    .swiper-button {
        &-prev,
        &-next {
            transition: $transition-base;
            position: relative;
            top: inherit;
            left: inherit;
            right: inherit;
            width: 40px;
            height: 40px;
            margin: 0;
            border-radius: 50%;
            border: solid 1px #282828;
            @include media-down(md) {
                width: 32px;
                height: 32px;
            }
            &::after {
                position: absolute;
                content: '';
                width: 9px;
                height: 9px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.43 8.43"><defs><style>.cls-1{fill:#282828;stroke-width:0px;}</style></defs><path class="cls-1" d="M0,8.43l8.43-4.22L0,0c.89,1.18,1.43,2.63,1.43,4.22s-.54,3.04-1.43,4.22Z"/></svg>'
                    )
                    no-repeat center / 100% auto;
                @include media-down(md) {
                    width: 7px;
                    height: 7px;
                }
            }
            @media (hover: hover) {
                &:hover {
                    background-color: #282828;
                    &::after {
                        background: svgUrlFunc(
                                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.43 8.43"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="M0,8.43l8.43-4.22L0,0c.89,1.18,1.43,2.63,1.43,4.22s-.54,3.04-1.43,4.22Z"/></svg>'
                            )
                            no-repeat center / 100% auto;
                    }
                }
            }
        }
        &-prev {
            &::after {
                transform: translate(-50%, -50%) rotate(180deg);
            }
        }
        &-next {
            @include media-down(sm) {
                // top: 34%;
                // left: map-get($spacers, 5);
                // width: 44px !important;
                // height: 44px !important;
            }
        }
    }
}
.block-newitem-item {
    @include media-up(md) {
        width: 192px;
    }
    @include media-down(md) {
        width: 154px;
    }
    @include media-down(sm) {
    }
    &__link {
        text-decoration: none !important;
        @media (hover: hover) {
            &:hover {
                .block-newitem-item__img {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
    &__img {
        overflow: hidden;
        aspect-ratio: 1 / 1;
        img {
            transition: $transition-base;
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }
    .title {
        margin: 15px 0 0 0;
        font-size: calc(15 / 16 * $font-size-base);
        color: #282828;
        letter-spacing: 0.06em;
        line-height: calc(24 / 15);
        @include media-down(sm) {
            font-size: calc(12 / 16 * $font-size-base);
            line-height: calc(19 / 12);
        }
    }
}
.block-newitem__btn-wrap {
    margin-top: 27px;
    padding-inline: map-get($spacers, 3);
    @include media-down(md) {
    }
    &__inner {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        max-width: 1100px;
        margin-inline: auto;
    }
}
.block-newitem__btn {
    @include c-button-border();
}
.sns {
    overflow: hidden;
    margin-bottom: 110px;
    padding-inline: map-get($spacers, 3);
    @include media-down(md) {
        margin-bottom: 75px;
    }
    &__inner {
        display: flex;
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
        @include media-up(md) {
            justify-content: space-between;
        }
        @include media-down(md) {
            flex-direction: column;
        }
    }
    h2 {
        @include font-en();
        margin-bottom: 35px;
        font-size: calc(16 / 16 * $font-size-base);
        text-align: center;
        letter-spacing: 0.06em;
        @include media-down(md) {
            margin-bottom: 15px;
        }
    }
    .facebook,
    .instagram {
        @include media-up(md) {
            width: calc((100% - 48px) / 2);
        }
        @include media-down(md) {
            width: 100%;
        }
    }
    .facebook {
        @include media-down(md) {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 40px;
        }
    }
    .fb-page {
        width: 100%;
        @include media-down(sm) {
            aspect-ratio: 1;
            overflow: hidden;
        }
    }
    .instafeed-wrap {
        overflow: auto;
        @include media-up(sm) {
            max-height: 500px;
        }
        @include media-down(sm) {
            aspect-ratio: 1;
        }
    }
    .instafeed__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 6px;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    .instafeed__item {
        a {
            aspect-ratio: 1 / 1;
            display: block;
            @media (hover: hover) {
                &:hover {
                    @include hoverOpacity;
                }
            }
        }
        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }
    .instafeed-btnwrap {
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }
    .instafeed-btn {
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        width: 255px;
        height: 44px;
        font-size: calc(16 / 16 * $font-size-base);
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        font-weight: 500;
        color: #333;
        text-decoration: none !important;
        text-align: center;
        text-transform: none;
        white-space: nowrap;
        line-height: 1;
        letter-spacing: 0.05rem;
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 0;
        @media (hover: hover) {
            &:hover {
                background-color: tint(#ddd, 50%);
            }
        }
    }
}
.bnr {
    margin-bottom: 90px;
    padding-inline: map-get($spacers, 3);
    @include media-down(md) {
        margin-bottom: 45px;
    }
    &__content {
        gap: 30px;
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
        @include media-down(lg) {
            gap: 15px;
        }
        @include media-up(sm) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
        @include media-down(sm) {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 28px;
        }
        img {
            width: 100%;
            height: auto;
        }
        a {
            @include media-down(sm) {
                width: 300px;
            }
            @media (hover: hover) {
                &:hover {
                    @include hoverOpacity;
                }
            }
        }
    }
}
.bnr-recruitment {
    margin-bottom: 160px;
    padding-inline: map-get($spacers, 3);
    @include media-down(md) {
        margin-bottom: 75px;
    }
    a {
        display: block;
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
        @media (hover: hover) {
            @include hoverOpacity;
        }
    }
    img {
        width: 100%;
        height: auto;
    }
}
