@mixin table-typeA {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border-top: solid 1px #d6d4d3;
    th,
    td {
        font-size: calc(16 / 16 * $font-size-base);
        font-feature-settings: normal;
        font-weight: normal;
        vertical-align: top;
        padding: 15px 0 15px 4px;
        color: #332a23;
        letter-spacing: 0.1em;
        line-height: 1.6;
        border-bottom: solid 1px #d6d4d3;
        @include media-down(md) {
            padding: 12px 0 12px 4px;
            font-size: calc(14 / 16 * $font-size-base);
        }
        @include media-down(sm) {
            font-size: calc(12 / 16 * $font-size-base);
        }
    }
    th {
        white-space: nowrap;
        @include media-up(md) {
            width: 140px;
        }
        @include media-down(md) {
            width: 120px;
        }
        @include media-down(sm) {
            width: 100px;
        }
    }
}
@mixin table-typeB {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border-top: solid 1px #d6d4d3;
    th,
    td {
        font-size: calc(16 / 16 * $font-size-base);
        font-feature-settings: normal;
        font-weight: normal;
        vertical-align: top;
        padding: 15px 0 15px 4px;
        text-align: center;
        color: #332a23;
        letter-spacing: 0.1em;
        line-height: 1.6;
        border-bottom: solid 1px #d6d4d3;
        @include media-down(md) {
            padding: 12px 0 12px 4px;
            font-size: calc(14 / 16 * $font-size-base);
        }
        @include media-down(sm) {
            font-size: calc(12 / 16 * $font-size-base);
        }
    }
    th {
        white-space: nowrap;
        &:first-of-type {
            width: 33.7%;
        }
    }
}