.rwd002-breadcrumbs {
  display: flex;
  padding-left: map-get($spacers, 4);
  padding-right: map-get($spacers, 4);
  margin-top: 40px;
  margin-bottom: 40px;

  @include media-up(lg) {
    padding-left: map-get($spacers, 5);
    padding-right: map-get($spacers, 5);
  }
  @include media-down(lg) {
    display: none;
  }
  &__list {
    @extend .breadcrumb;
    width: 100%;
    max-width: 1100px;
    margin: auto;
  }
  &__item {
    @extend .breadcrumb-item;
    @extend .text-truncate;
    padding-left: 0;
    margin-right: 5px;
    max-width: 33%;
    font-size: calc(13 / 16 * $font-size-base);
    color: #282828;
    letter-spacing: 0.05em;
    font-feature-settings: "palt";
    @include media-down(md) {
      font-size: $font-size-xs;
      max-width: 14em;
    }
    & + &{
      padding-left: 0;
    }
    & + &::before {
      content: "ー";
      padding-right: 5px;
      @include media-down(md) {
        content: svgUrlFunc(
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="5" height="9"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$primary}" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>'
        );
      }
    }
    &:first-child {
      color: #cfad70;
    }
  }
}
