.is--service.is--list {
    .rwd002-headline__wrap {
        display: none;
    }
    .rwd002-container {
        margin-top: 0;
    }
    .rwd002-list-content__inner {
        max-width: 1100px;
    }
    .rwd002-list-content {
        margin-top: 90px;
        @include media-down(md) {
            margin-top: 40px;
        }
        &__list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 55px 100px;
            margin: 0 0 100px;
            @include media-down(xl) {
                gap: 50px 80px;
            }
            @include media-down(lg) {
                gap: 50px;
            }
            @include media-down(md) {
                grid-template-columns: repeat(2, 1fr);
                margin-bottom: 50px;
            }
            @include media-down(sm) {
                grid-template-columns: 1fr;
            }
        }
        &__item {
            width: 100%;
            margin: 0;
            padding: 0;
            .title {
                font-size: calc(18 / 16 * $font-size-base);
                font-weight: normal;
                font-feature-settings: normal;
                letter-spacing: 0.06em;
                line-height: calc(24 / 18);
            }
            .desc {
                font-size: calc(14 / 16 * $font-size-base);
                font-weight: normal;
                font-feature-settings: normal;
                letter-spacing: 0.1em;
                line-height: calc(22 / 14);
            }
        }
        &__thumb {
            aspect-ratio: 300 / 210;
        }
    }
    .rwd002-block-freearea {
        margin: 0;
        padding: 0;
        &__inner {
            max-width: inherit;
            padding: 0;
        }
    }
}
.is--service.is--detail {
    .rwd002-block.is-head {
        display: none;
    }
    .rwd002-container {
        margin-top: 15px;
        @include media-down(md) {
            margin-top: 2px;
        }
    }
    .rwd002-detail {
        margin-top: 0;
    }
    .rwd002-detail__head {
        max-width: 1100px;
    }
    .rwd002-detail__title {
        margin-bottom: 18px;
        padding-bottom: 15px;
        font-size: calc(30 / 16 * $font-size-base);
        font-weight: normal;
        text-align: center;
        letter-spacing: 0.1em;
        line-height: calc(34 / 24);
        border-bottom-color: #332a23;
        @include media-down(md) {
            margin-bottom: 16px;
            padding-bottom: 13px;
            font-size: calc(19 / 16 * $font-size-base);
        }
    }
    .rwd002-detail__desc {
        margin: 0 0 100px;
        font-size: calc(18 / 16 * $font-size-base);
        text-align: center;
        letter-spacing: 0.1em;
        @include media-down(md) {
            margin-bottom: 40px;
            font-size: calc(12 / 16 * $font-size-base);
        }
    }
    .rwd002-detail__image {
        margin-bottom: 50px;
        @include media-down(md) {
            margin-bottom: 40px;
        }
    }
    .rwd002-detail__entry {
        max-width: 800px;
        p {
            font-feature-settings: normal;
            letter-spacing: 0.1em;
            line-height: calc(35 / 16);
            @include media-down(md) {
                font-size: calc(13 / 16 * $font-size-base);
                line-height: calc(26 / 13);
            }
        }
        h3 {
            margin: 70px 0 30px;
            font-size: calc(18 / 16 * $font-size-base);
            font-weight: bold;
            text-align: center;
            letter-spacing: 0.06em;
            line-height: calc(28 / 18);
            @include media-down(md) {
                margin: 40px 0 20px;
                font-size: calc(14 / 16 * $font-size-base);
                line-height: calc(22 / 14);
            }
        }
        ._tyuki-text {
            margin-top: 18px;
            @include media-down(md) {
                margin-top: 13px;
                font-size: calc(10 / 16 * $font-size-base);
            }
        }
        ._table1 {
            @include table-typeA();
            th {
                width: 230px;
                @include media-down(md) {
                    width: 145px;
                }
            }
        }
        ._table2 {
            @include table-typeB();
            @include media-down(md) {
                th:first-of-type {
                    width: 80px;
                }
            }
        }
        .flow-list {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin: 0;
            padding: 0;
            list-style-type: none;
            border-collapse: collapse;
            border-spacing: 0;
            @include media-down(md) {
                width: calc(100% + 3.5rem);
                margin-left: -1.75rem;
            }
            li {
                width: 100%;
                display: flex;
                align-items: center;
                margin: 0;
                font-size: calc(16 / 16 * $font-size-base);
                font-feature-settings: normal;
                font-weight: normal;
                vertical-align: middle;
                padding-block: 11px;
                color: #332a23;
                letter-spacing: 0.1em;
                line-height: calc(25 / 16);
                @include media-down(md) {
                    font-size: calc(13 / 16 * $font-size-base);
                }
                @include media-up(sm) {
                    min-height: 85px;
                }
                @include media-down(sm) {
                    min-height: 100px;
                }
                &:nth-of-type(odd) {
                    background-color: #ebeded;
                }
            }
            h4 {
                margin: 0;
                padding-left: 35px;
                font-size: calc(16 / 16 * $font-size-base);
                white-space: nowrap;
                @include media-up(md) {
                    width: 210px;
                }
                @include media-down(md) {
                    width: 115px;
                    padding-left: map-get($spacers, 3);
                    font-size: calc(13 / 16 * $font-size-base);
                }
            }
            p {
                margin: 0;
                padding-right: 30px;
                line-height: calc(25 / 16);
                @include media-up(md) {
                    width: calc(100% - 210px);
                }
                @include media-down(md) {
                    width: calc(100% - 115px);
                    padding-right: map-get($spacers, 3);
                    letter-spacing: 0.02em;
                }
            }
        }
    }
}
.service-head {
    margin-bottom: 80px;
    padding: 95px 0 15px;
    background-color: #f2f1f0;
    @include media-down(md) {
        margin-bottom: 35px;
        padding: 53px 0 40px;
    }
    &__wrap {
        padding-inline: map-get($spacers, 3);
        @include media-down(md) {
            padding-inline: 35px;
        }
    }
    &__inner {
        width: 100%;
        max-width: 725px;
        margin-inline: auto;
    }
    ._title {
        @include font-en();
        margin: 10px 0;
        font-size: calc(46 / 16 * $font-size-base);
        text-align: center;
        letter-spacing: 0.1em;
        line-height: 1;
        @include media-down(md) {
            margin: 5px 0;
            font-size: calc(32 / 16 * $font-size-base);
        }
    }
    ._subtitle {
        margin: 0;
        font-size: calc(16 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        letter-spacing: 0.06em;
        @include media-down(md) {
            font-size: calc(12 / 16 * $font-size-base);
        }
    }
    ._text {
        margin: 65px 0;
        font-size: calc(16 / 16 * $font-size-base);
        letter-spacing: 0.06em;
        line-height: calc(30 / 16);
        @include media-down(md) {
            margin: 33px 0 70px;
            font-size: calc(13 / 16 * $font-size-base);
        }
    }
    img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: auto;
    }
}
.service-btnwrap {
    display: flex;
    gap: 25px;
    margin-top: 90px;
    @include media-up(md) {
        justify-content: space-between;
    }
    @include media-down(md) {
        flex-direction: column;
        align-items: center;
        margin-top: 60px;
    }
    a {
        @include c-button-reserve();
    }
}
