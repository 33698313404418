.rwd002-pagination {
  margin: 4em auto 90px;
  font-feature-settings: "palt";
  @include media-down(lg) {
    margin-bottom: 70px;
  }

  &__totalpages {
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);
    margin-bottom: 1.5em;
    font-size: 0.8125rem;
    font-weight: $font-weight-bold;
    letter-spacing: 0.3em;
    text-indent: 0.3em;
    text-align: center;
  }
  &__pageNav {
    width: min(95%, 1100px);
    margin: 0 auto;
    border: 1px solid #D4D4D4;
    border-width: 1px 0;
    display: flex;
    justify-content: center;
  }
  .is-prev,
  .is-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 337px;
    font-weight: $font-weight-normal;
    color: $dark;
    font-size: 1rem;
    line-height: 1em;
    font-family: "Roboto", $font-family-sans-serif;
    letter-spacing: 0.2em;
    text-indent: 0.2em;
    background: transparent;
    transition: $transition-base;
    position: relative;

    > span {
      order: 1;
      flex-grow: 1;
      display: block;
      padding: 1em;
      font-size: 1rem;
      letter-spacing: 0.1em;
      text-indent: 0;
      text-align: left;
      font-weight: $font-weight-normal;
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 14px;
      height: 8px;
      background: url(/assets/img/icon-arrow.svg) no-repeat 0 / contain;
      top: 0;
      bottom: 0;
      margin: auto;
      transition: .3s;
    }
    .thumb {
      max-height: 85px;
      max-width: 120px;
      overflow: hidden;
      @include media-down(sm) {
        display: none;
      }
      img {
        display: block;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .is-prev > .thumb {
    order: 0;
  }
  .is-next > .thumb {
    order: 1;
  }
  .is-prev::before {
    left: 0;
    transform: scaleX(-1);
  }
  .is-next::before {
    right: 0;
  }

  .is-prev.disable,
  .is-next.disable {
    color: tint($dark, 20%);
    background: transparent;
    border: 0px solid $light;
    opacity: 0.7;
    pointer-events: none;
  }
  .is-prev:hover:not(.disable),
  .is-next:hover:not(.disable) {
    text-decoration: none;
    color: $white;
    background-color: $dark;
    &:before {
      background-image: url(/assets/img/icon-arrow_wh.svg);
    }
  }

  .is-backbtn {
    flex-grow: 1;
    padding: 0px;
    text-align: center;
    @include media-up(md) {
      width: 100%;
      max-width: 126px;
      margin: 0;
    }
    @include media-down(sm) {
      max-width: 80px;
    }
  }
  .is-backbtn a {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    width: 100%;
    height: 100%;
    padding: 24px 15px;
    // font-weight: 700;
    letter-spacing: 0.06em;
    text-indent: 0.06em;
    transition: $transition-base;
    // color: $dark;
    background: transparent;
    // font-size: 0;
    font-size: calc(16 / 16 * $font-size-base);
    color: #282828;
    // text-indent: -9999px;
    @include media-up(sm) {
      height: 100px;
    }
    @include media-down(sm) {
      padding: 12px;
      height: 80px;
      font-size: calc(13 / 16 * $font-size-base);
    }
    &:after {
      display: none !important;
      content: "";
      display: block;
      width: 30px;
      height: 30px;
      margin: 0 auto;
      background: svgUrlFunc(
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.27 27.6"><defs><style>.c{fill:#{$light};}</style></defs><g><circle class="c" cx="2.67" cy="2.67" r="2.67"/><circle class="c" cx="15.14" cy="2.67" r="2.67"/><circle class="c" cx="27.6" cy="2.67" r="2.67"/><circle class="c" cx="2.67" cy="13.8" r="2.67"/><circle class="c" cx="15.14" cy="13.8" r="2.67"/><circle class="c" cx="27.6" cy="13.8" r="2.67"/><circle class="c" cx="2.67" cy="24.93" r="2.67"/><circle class="c" cx="15.14" cy="24.93" r="2.67"/><circle class="c" cx="27.6" cy="24.93" r="2.67"/></g></svg>'
        )
        center / contain no-repeat;
      @include media-down(sm) {
        width: 20px;
        height: 20px;
      }
    }
    &:hover {
      text-decoration: none;
      background: $dark;
      color: $white;
    }
  }

  @include media-down(md) {
    margin-bottom: 70px;
    &__pageNav {
      width: 100%;
      padding-inline: 25px;
    }
    .is-next, .is-prev {
      font-size: 13px;
    }
  }
}

.is--list .rwd002-pagination {
  &__pageNav {
    border-bottom: none;
    padding-top: 27px;
    .is-prev, .is-next {
      text-indent: -9999px;
      display: block;
      width: 30px;
      height: 30px;
      &::before {
        inset: 0;
      }
      &.disable {
        opacity: 0;
      }
      &:hover {
        background: none;
        &::before {
          background-image: url(/assets/img/icon-arrow.svg);
        }
      }
    }
  }

  &__pages {
    padding: 0;
    margin: 0 15px;
    display: flex;
    justify-content: center;
    column-gap: 20px;
    list-style: none;
    li {
      position: relative;
      font-size: 1rem;
      margin: 0;
      letter-spacing: 0.2em;
      text-indent: 0.2em;
      font-weight: $font-weight-normal;
      font-family: "Roboto", $font-family-sans-serif;

      span,
      a,
      i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        transition: $transition-base;
        color: $gray-800;
        border-radius: 50%;
      }
      span {
        background: #F0F0F0;
      }
      a {
        transition: $transition-base;
        background: transparent;
        &:hover {
          text-decoration: none;
          background: #F0F0F0;
        }
      }
      i {
        color: $dark;
        letter-spacing: 0.02em;
        text-indent: 0.02em;
        padding-bottom: 0.5em;
      }
      &:after {
        left: 50%;
        position: absolute;
        bottom: 0;
      }
      &:hover:after,
      &[class*="current"]:after,
      &[class*="active"]:after {
        display: none;
      }
    }
  }

  @include media-down(md) {
    &__pageNav {
      padding-top: 22px;
      .is-prev, .is-next {
        width: 24px;
        height: 24px;
      }
    }

    &__pages {
      padding: 0;
      margin: 0 10px;
      column-gap: 15px;
      li {

        span,
        a,
        i {
          font-size: 12px;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}