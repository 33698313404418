.rwd002-profile {
    a {
        color: $primary;
        text-decoration: underline;
    }
    &__title {
        max-width: 100%;
        width: 1000px;
        margin: 0 auto;
        padding: 0 1.25rem;
        @include media-down(sm) {
            padding: 0 1.6875rem;
        }
    }
    &__entry {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        padding: 0 1.25rem;
        @include media-down(sm) {
            padding: 0 1.6875rem;
        }
        .is-main & {
            @include media-up(xl) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    &__table {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        border: none;
        @include media-down(sm) {
            max-width: 100%;
        }
        th,
        td {
            padding: 1em;
            background: transparent;
            border: solid 1px #e9e9e9;
            border-width: 0 0 1px;
            font-size: 1em;
            line-height: 2;
            @include media-down(sm) {
                display: block;
                padding-left: 0;
                padding-right: 0;
            }
            address {
                margin-bottom: 0;
            }
            iframe {
                max-width: 100%;
            }
            p {
                margin-top: 0;
                margin-bottom: 0;
                & + p {
                    margin-top: 1em;
                }
            }
        }

        th {
            vertical-align: top;
            border-color: #bfc0c0;
            font-weight: $font-weight-bold;
            letter-spacing: 0.1em;
            white-space: nowrap;
            @include media-up(md) {
                width: 150px;
            }
            @include media-down(sm) {
                padding-bottom: 0;
                border: none;
            }
        }
        td {
            letter-spacing: 0.16em;
            @include media-up(md) {
                padding-left: 2em;
            }
            @include media-down(sm) {
                padding-top: 0.5em;
                padding-bottom: 0.9375rem;
            }
        }

        //写真のthはスマホ表示で削除
        .is-prof12 {
            th {
                @include media-down(sm) {
                    display: none;
                }
            }
            td {
                @include media-down(sm) {
                    padding-top: 1em;
                }
            }
        }
    }

    &__img {
        margin: 0;
        img {
            max-width: 100%;
            height: auto;
        }
        .is-caption {
            margin-top: 0.5em;
            font-size: 0.875rem;
        }
    }

    &__other {
        padding: 2em 1em 0;
        letter-spacing: 0.1em;
        @include media-down(md) {
            padding: 2em 0 0;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
}
.is--profile {
    .rwd002-container {
        margin-top: 0;
    }
    .rwd002-main {
        margin-bottom: 110px;
        @include media-down(md) {
            margin-bottom: 60px;
        }
    }
    .editor-template {
        table {
            @include table-typeA();
            margin-bottom: 70px;
            @include media-down(md) {
                margin-bottom: 50px;
            }
            th,
            td {
                font-feature-settings: 'palt';
                line-height: 2;
            }
            th {
                width: 190px;
                @include media-down(md) {
                    width: 140px;
                }
                @include media-down(sm) {
                    width: 80px;
                }
            }
        }
    }
    .profile-container-wrap {
        padding-inline: map-get($spacers, 3);
    }
    .profile-container {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
    .profile-container--md {
        width: 100%;
        max-width: 720px;
        margin-inline: auto;
    }
    table {
        @include table-typeA();
        margin-bottom: 70px;
        @include media-down(md) {
            margin-bottom: 50px;
        }
        th,
        td {
            font-feature-settings: 'palt';
            line-height: 2;
        }
        th {
            width: 190px;
            @include media-down(md) {
                width: 140px;
            }
            @include media-down(sm) {
                width: 80px;
            }
        }
    }
    .profile-title {
        margin-bottom: 25px;
        font-size: calc(24 / 16 * $font-size-base);
        font-feature-settings: 'palt';
        text-align: center;
        letter-spacing: 0.1em;
        line-height: calc(32 / 24);
        @include media-down(md) {
            margin-bottom: 20px;
            font-size: calc(16 / 16 * $font-size-base);
        }
    }
    .block-shop__loop {
        margin-bottom: 120px;
        @include media-down(md) {
            margin-bottom: 55px;
        }
    }
    .profile-view {
        margin-bottom: 95px;
        @include media-down(md) {
            margin-bottom: 75px;
        }
        iframe {
            width: 100%;
            height: 515px;
            @include media-down(md) {
                height: 177px;
            }
        }
    }
    .profile-map {
        overflow: hidden;
        width: 100%;
        height: 515px;
        margin-bottom: 85px;
        @include media-down(md) {
            height: 330px;
            margin-bottom: 55px;
        }
        iframe {
            transform: translateY(-160px);
            display: block;
            width: 100%;
            height: 875px;
            @include media-down(md) {
                transform: translateY(-100px);
                height: 560px;
            }
        }
    }
    .profile-payment {
        @include media-up(md) {
            display: flex;
        }
        h3 {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            font-size: calc(15 / 16 * $font-size-base);
            color: #fff;
            letter-spacing: 0.1em;
            background-color: #282828;
            @include media-up(md) {
                flex-shrink: 0;
                width: 155px;
            }
            @include media-down(md) {
                padding: 12px;
                font-size: calc(12 / 16 * $font-size-base);
            }
        }
        ._body {
            padding: 15px 0 20px 40px;
            @include media-down(md) {
                padding: 20px 0 0;
            }
        }
        h4 {
            position: relative;
            margin-bottom: 10px;
            padding-left: 23px;
            font-size: calc(16 / 16 * $font-size-base);
            color: #282828;
            letter-spacing: 0.1em;
            line-height: 2;
            @include media-down(md) {
                padding-left: 16px;
                font-size: calc(11 / 16 * $font-size-base);
            }
            &::before {
                content: '';
                position: absolute;
                top: 0.5rem;
                left: 0;
                width: 14px;
                height: 14px;
                background-color: #282828;
                border-radius: 50%;
                @include media-down(md) {
                    top: 0.36rem;
                    width: 10px;
                    height: 10px;
                }
            }
        }
        img {
            margin-left: 23px;
            @include media-down(md) {
                margin-left: 16px;
            }
        }
        img {
            max-width: 100%;
            height: auto;
        }
        ._card {
            @include media-down(md) {
                width: 290px;
            }
        }
        ._money {
            @include media-down(md) {
                width: 268px;
            }
        }
    }
}
.profile-headline {
    aspect-ratio: 1400 / 560;
    width: 100%;
    background-attachment: fixed;
    background-image: url(/assets/img/webp/shop/pho-headline@2x.webp);
    background-position: center;
    background-size: cover;
    @media (max-width: 1200px) {
        background-attachment: inherit;
    }
    @include media-up(md) {
        min-height: 440px;
    }
    @include media-down(md) {
        min-height: 305px;
    }
    @include media-down(sm) {
        height: 150px;
        min-height: inherit;
        background-image: url(/assets/img/webp/shop/pho-headline_sp@2x.webp);
    }
}
.profile-shopinfo {
    padding: 100px 0 130px;
    @include media-down(md) {
        padding: 45px 0 70px;
    }
    &__title {
        @include font-en();
        margin-bottom: 65px;
        font-size: calc(35 / 16 * $font-size-base);
        color: #282828;
        line-height: calc(50 / 35);
        text-align: center;
        @include media-down(md) {
            margin-bottom: 35px;
            font-size: calc(22 / 16 * $font-size-base);
        }
    }
    figure {
        margin: 0;
        img {
            display: block;
            margin: auto;
            max-width: 100%;
            height: auto;
        }
    }
    figcaption {
        @include font-en();
        margin-bottom: 20px;
        font-size: calc(20 / 16 * $font-size-base);
        color: #282828;
        text-align: center;
        @include media-down(md) {
            margin-bottom: 15px;
            font-size: calc(10 / 16 * $font-size-base);
        }
    }
}
