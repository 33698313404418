@keyframes up_down_line {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-8px);
    }
}
@keyframes fix-a {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(13px);
    }
}

.footer-contact {
    background: url(/assets/img/webp/bg-contact@2x.webp) no-repeat center /
        cover;
    @include media-down(sm) {
        background-image: url(/assets/img/webp/bg-contact_sp@2x.webp);
    }
    &__link {
        position: relative;
        display: block;
        width: 100%;
        height: 440px;
        padding-inline: map-get($spacers, 5);
        text-decoration: none !important;
        @include media-down(md) {
            height: 320px;
            padding-inline: map-get($spacers, 3);
        }
        @include media-down(sm) {
            height: 220px;
        }
        @media (hover: hover) {
            &:hover {
                ._icon-circle {
                    transition-duration: 0s;
                    border: none;
                    &::before {
                        transition-delay: 0.6s;
                        opacity: 0.35;
                    }
                    &::after {
                        animation: circleBdrIcon 3s $ease-easy infinite;
                    }
                    ._circle {
                        animation: circleBdrFill 0.6s $ease-easy 0s 1 forwards;
                    }
                }
            }
        }
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(40, 40, 40, 0.8);
            mix-blend-mode: multiply;
        }
    }
    &__contants {
        width: 100%;
        max-width: 1100px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-inline: auto;
    }
    &__title {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        color: #fff;
        @include media-down(sm) {
            margin-bottom: 25px;
        }
        span {
            line-height: 1;
        }
        ._en {
            margin-bottom: 15px;
            font-size: calc(35 / 16 * $font-size-base);
            @include font-en();
            @include media-down(md) {
                margin-bottom: 10px;
                font-size: calc(26 / 16 * $font-size-base);
            }
        }
        ._ja {
            font-size: calc(14 / 16 * $font-size-base);
            letter-spacing: 0.06em;
            @include media-down(md) {
                font-size: calc(10 / 16 * $font-size-base);
            }
        }
    }
    &__btn {
        @include c-button-border-white();
        z-index: 1;
        @include media-down(sm) {
            margin-top: auto;
            margin-bottom: map-get($spacers, 3);
        }
    }
}

.rwd002-footerHead {
    display: flex;
    @media (min-width: 1150px) {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 65px;
    }
    @media (max-width: 1149px) {
        flex-direction: column;
        margin-bottom: 35px;
    }
    @include media-down(lg) {
        align-items: center;
    }
    &__link {
        @media (max-width: 1149px) {
            margin-bottom: 20px;
        }
        @include media-down(lg) {
            display: none;
        }
        @media (hover: hover) {
            @include hoverOpacity;
        }
    }
}
.rwd002-footerSocial {
    display: flex;
    align-items: center;
    gap: 30px;
    @include media-up(lg) {
    }
    a {
        @media (hover: hover) {
            @include hoverOpacity;
        }
    }
    &__title {
        margin: 0 3px 0 0;
        font-size: calc(16 / 16 * $font-size-base);
        @include font-en();
        line-height: 1;
    }
    &__btn {
        transition: $transition-base;
        @media (hover: hover) {
        }
    }
    // &__btn.is-fb{
    //   &:hover,&:focus{
    //     color: $sns-facebook-bg;
    //   }
    // }
    // &__btn.is-insta{
    //   &:hover,&:focus{
    //     color: $sns-twitter-bg;
    //   }
    // }
    // &__btn.is-line{
    //   &:hover,&:focus{
    //     color: $sns-line-bg;
    //   }
    // }
}

.footer-fixbnr {
    // position: sticky;
    position: fixed;
    z-index: 10;
    width: 350px;
    margin-left: auto;
    display: none;
    @include media-up(lg) {
        bottom: map-get($spacers, 5);
        right: map-get($spacers, 5);
        animation: fix-a 2.5s ease-in-out infinite alternate;
    }
    @include media-down(lg) {
        bottom: 0;
    }
    @include media-down(sm) {
        width: 280px;
    }
    @media (hover: hover) {
        &:hover {
            @include hoverOpacity;
        }
    }
    &__close {
        position: absolute;
        top: -9px;
        right: -9px;
        cursor: pointer;
        @include media-down(sm) {
            top: -7px;
            right: -7px;
        }
        img {
            display: block;
            @include media-down(sm) {
                width: 15px;
                height: auto;
            }
        }
    }
    &__link {
        display: block;
        img {
            width: 100%;
            height: auto;
        }
    }
}
.rwd002-pagetop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    width: 80px;
    height: 80px;
    font-size: calc(12 / 16 * $font-size-base);
    @include font-en();
    color: #fff !important;
    text-decoration: none;
    transform-origin: bottom;
    // border: 1px solid $body-color;
    background-color: #282828;

    @include media-up(lg) {
        right: map-get($spacers, 5);
    }
    @include media-down(lg) {
        right: calc(50% - 40px);
    }
    &:before {
        content: '';
        display: block;
        margin-top: 0.2em;
        margin-bottom: 0.8em;
        width: 9px;
        height: 9px;
        background: svgUrlFunc(
                '<svg id="arr" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.43 8.43"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="M8.43,8.43L4.22,0,0,8.43c1.18-.89,2.63-1.43,4.22-1.43s3.04.54,4.22,1.43Z"/></svg>'
            )
            no-repeat center / contain;
    }
    &:hover,
    &:focus {
        text-decoration: none;
        &:before {
            animation: up_down_line 0.9s infinite ease-in-out;
        }
    }
    &__wrap {
        width: 100%;
        z-index: 10;
        transition: $transition-base;
        @include media-up(lg) {
            position: fixed;
            position: sticky;
            bottom: 20px;
            margin: 0 auto;
            padding-left: map-get($spacers, 1);
            padding-right: map-get($spacers, 1);
        }
        @include media-down(lg) {
            position: relative;
            display: none !important;
        }
        &.hidden {
            transform: translateY(200px);
            opacity: 0;
        }
    }
}

.rwd002-footer {
    color: $white;
    background-color: #443d3e;
    @include media-up(lg) {
        padding: 80px map-get($spacers, 5) 35px;
    }
    @include media-down(lg) {
        padding: 45px map-get($spacers, 3) 25px;
    }

    &__inner {
        display: flex;
        max-width: 1100px;
        margin: 0 auto;
        @include media-up(lg) {
        }
        @include media-down(lg) {
            flex-direction: column;
            align-items: stretch;
        }
    }

    &__info {
        @include media-up(lg) {
            flex-grow: 1;
            padding-right: map-get($spacers, 3);
        }
        @include media-down(lg) {
            margin-bottom: 75px;
        }
    }

    &__companyLogo {
        margin-bottom: 65px;
        @include media-down(lg) {
            display: flex;
            justify-content: center;
            margin-bottom: 40px;
        }
    }

    &__companyBody {
        @include media-down(lg) {
            width: fit-content;
            margin-inline: auto;
        }
        @include media-down(sm) {
            width: 100%;
        }
    }

    &__address {
        margin: 0;
        font-size: calc(14 / 16 * $font-size-base);
        color: #fff;
        letter-spacing: 0.06em;
        line-height: calc(28 / 14);
        @include media-down(md) {
            font-size: calc(12 / 16 * $font-size-base);
        }
        .tel {
            display: inline-block;
            margin: 15px 0;
            font-size: calc(28 / 16 * $font-size-base);
            font-family: 'Roboto', $font-family-sans-serif;
            letter-spacing: 0.1em;
            line-height: 1;
            @include media-down(md) {
                margin: 10px 0;
                font-size: calc(37 / 16 * $font-size-base);
            }
        }
        .holiday {
        }
        .number {
        }
    }

    &__content {
        width: 100%;
        max-width: 50%;
        @media (min-width: 1150px) {
            max-width: 630px;
        }
        @include media-down(lg) {
            max-width: 100%;
            padding-inline: map-get($spacers, 2);
        }
    }

    &__bottom {
        width: 100%;
        max-width: 1100px;
        margin: 5px auto 0;
        @include media-down(lg) {
            margin-top: 50px;
        }
    }

    &__copyright,
    &__login {
        display: block;
        text-align: right;
        letter-spacing: 0.06em;
        text-indent: 0.06em;
        // font-feature-settings: "palt";
    }

    &__copyright {
        font-size: calc(10 / 16 * $font-size-base);
        color: #b4b5b5;
        @include media-down(md) {
            text-align: center;
        }
    }

    &__login {
        margin-top: 20px;
        font-size: calc(12 / 16 * $font-size-base);
        color: #8d8d8e;
    }
}

.rwd002-footerNav {
    @include media-up(lg) {
        // display: grid;
        // grid-template: 1fr auto / 2fr 1fr;
        // flex-basis: 60%;
    }
    @include media-down(lg) {
        // display: flex;
        // flex-wrap: wrap;
    }
    @include media-down(md) {
        display: block;
    }
    &__list,
    &__sublist {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    &__list {
        font-size: calc(16 / 16 * $font-size-base);
        color: #fff;
        @include media-up(md) {
            // column-count: 2;
            // column-gap: 1em;
        }
        @include media-up(lg) {
            display: flex;
            flex-wrap: wrap;
            column-gap: 29px;
        }
        @include media-down(lg) {
            font-size: calc(15 / 16 * $font-size-base);
            // margin-right: 2.5em;
        }
        @include media-down(md) {
            // flex-grow: 1;
            // margin-right: 0;
            // margin-left: -0.8em;
            // margin-right: -0.8em;
            // margin-bottom: 1.5em;
        }
    }
    &__sublist {
        font-size: calc(12 / 16 * $font-size-base);
        @include font-en();
        color: #a7abaf;
        @include media-up(lg) {
            display: none;
        }
        @include media-down(lg) {
            display: flex;
            margin-top: 25px;
        }
    }
}

.rwd002-footerNav-item {
    display: flex;
    flex-wrap: wrap;
    @media (min-width: 1150px) {
        &:nth-of-type(8) {
            margin-left: 83px;
        }
    }
    @include media-down(lg) {
        position: relative;
        border: solid #ffffff;
        border-width: 1px 0;
        & + & {
            border-top-width: 0;
        }
        &.is-sitemap,
        &.is-privacy {
            display: none;
        }
    }
    &__link {
        position: relative;
        display: inline-block;
        padding: 10px 0;
        line-height: 1;
        @include font-en();
        &:hover,
        &:focus {
            color: #fff;
        }
        // &:before {
        //   position: absolute;
        //   content: "";
        //   height: 1px;
        //   width: 0.3em;
        //   top: 1em;
        //   left: 0;
        //   background-color: darken($white, 20%);
        // }
        @include media-down(lg) {
            flex-grow: 1;
            flex-basis: calc(100% - 40px);
            padding: 15px 40px 15px 17px;
            text-decoration: none !important;
            // &:before {
            //   top: 1.25em;
            //   left: 0.7em;
            // }
        }
    }
}

.rwd002-footerNav-subitem {
    &__link {
        display: inline-block;
        margin: 0;
        padding: 0.3em 0;
        transition: $transition-base;
        &:hover,
        &:focus {
            color: $white;
        }
        @include media-down(lg) {
            margin-left: 17px;
        }
    }
}

.rwd002-footer-subnav {
    @include media-up(md) {
        display: none;
    }
    &__tgl {
        @extend .btn;

        &:focus {
            box-shadow: none;
        }

        @include media-up(md) {
            display: none;
        }
        @include media-down(md) {
            position: relative;
            flex-basis: 40px;
            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: calc(50% - 5px);
                width: 10px;
                height: 1px;
                background-color: #ffffff;
                transition: transform 0.2s ease-in-out;
            }

            &:after {
                transform: rotate(-90deg);
            }

            &.is-active {
                &:before {
                    transform: rotate(180deg);
                }
                &:after {
                    transform: rotate(0deg);
                }
            }
        }
    }
    &__list {
        list-style: none;
        @include media-down(md) {
            padding-left: 1.3em;
            padding-bottom: 1.0em;
        }
    }
}

.rwd002-footer-subnav-item {
    &__link {
        position: relative;
        padding-left: 1em !important;
        @include font-en();
        font-size: calc(12 / 16 * $font-size-base);
        letter-spacing: 0;
        @include media-down(md) {
            display: block;
            padding: 0.32em 0;
        }
        &:before {
          position: absolute;
          content: "";
          height: 1px;
          width: 4px;
          top: 1em;
          left: 0;
          background-color: #fff;
        }
    }
}

.rwd002-footer-search {
    margin-top: 2em;
    @include media-down(lg) {
        width: 100%;
        margin-top: 1.5em;
        margin-bottom: 3em;
    }
    &__inner {
        position: relative;
        max-width: 320px;
        @include media-down(md) {
            margin-left: -0.8em;
            margin-right: -0.8em;
        }
    }
    &__input {
        @extend .form-control;
        padding-right: 2.2em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        background-color: tint($dark, 10%);
        transition: $transition-base;
        border-width: 0;
    }
    &__btn {
        @extend .btn;
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc(100% - 2.2em);
        width: 2.2em;
        padding: 0 0.5em;
        pointer-events: none;
        transition: $transition-base;
        > svg {
            width: 1em;
            height: 100%;
            fill: tint($dark, 30%);
        }
    }
    &__btn:focus,
    &__input:focus + &__btn {
        pointer-events: auto;
        right: 0;
    }
}
