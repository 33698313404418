.content .box {
    width: 300px;
    height: 300px;
    background-color: #000;
}
.is--concept {
    .rwd002-container {
        margin-top: 0;
    }
    .rwd002-main {
        @include media-up(lg) {
            margin-bottom: -100px;
        }
    }
    .rwd002-detail {
        margin: 0;
        padding: 0;
    }
    .rwd002-detail__entry {
        max-width: inherit;
    }
    .rwd002-breadcrumbs {
    }
}
.cocept-container {
    &__inner {
        padding-inline: map-get($spacers, 3);
    }
}
.concept-headline {
    aspect-ratio: 1400 / 560;
    width: 100%;
    background-attachment: fixed;
    background-image: url(/assets/img/webp/concept/pho-headline@2x.webp);
    background-position: center;
    background-size: cover;
    @media (max-width: 1200px) {
        background-attachment: inherit;
    }
    @include media-up(md) {
        min-height: 440px;
    }
    @include media-down(md) {
        min-height: 305px;
    }
    @include media-down(sm) {
        min-height: inherit;
        height: 150px;
        background-image: url(/assets/img/webp/concept/pho-headline_sp@2x.webp);
    }
}
.concept-lead {
    position: relative;
    margin-bottom: 145px;
    padding-top: 100px;
    padding-inline: map-get($spacers, 3);
    @include media-down(md) {
        padding-top: 45px;
        margin-bottom: 85px;
    }
    &__inner {
        width: 100%;
        max-width: 1100px;
        margin-inline: auto;
        @include media-up(md) {
            display: flex;
            justify-content: space-between;
            gap: 85px;
        }
    }
    &__title {
        flex-shrink: 0;
        @include font-en();
        margin-bottom: 0;
        font-size: calc(35 / 16 * $font-size-base);
        @include media-up(md) {
            margin-top: -5px;
        }
        @include media-down(md) {
            margin-bottom: 22px;
            font-size: calc(22 / 16 * $font-size-base);
        }
    }
    &__subtitle {
        margin-bottom: 75px;
        font-family: $font-family-serif;
        font-size: calc(25 / 16 * $font-size-base);
        letter-spacing: 0.25em;
        line-height: calc(30 / 18);
        @include media-down(md) {
            margin-bottom: 25px;
            font-size: calc(22 / 16 * $font-size-base);
        }
        @include media-down(sm) {
            font-size: calc(18 / 16 * $font-size-base);
        }
    }
    &__body {
        width: 100%;
        max-width: 700px;
        p {
            font-family: $font-family-serif;
            font-size: calc(16 / 16 * $font-size-base);
            letter-spacing: 0.15em;
            line-height: calc(35 / 16);
            &:not(:last-of-type) {
                margin-bottom: 40px;
                @include media-down(md) {
                    margin-bottom: 25px;
                }
            }
            &:last-of-type {
                margin-bottom: 0;
                @include media-down(md) {
                    margin-bottom: 40px;
                }
            }
            @include media-down(md) {
                font-size: calc(15 / 16 * $font-size-base);
                font-feature-settings: 'palt';
                line-height: calc(21 / 12);
            }
            @include media-down(sm) {
                font-size: calc(12 / 16 * $font-size-base);
            }
        }
    }
    ._image {
        @include media-up(md) {
            position: absolute;
            bottom: 0;
            left: 0;
        }
        @include media-down(md) {
            position: relative;
            left: -1.75rem;
        }
        @include media-down(sm) {
            width: 162px;
            height: auto;
        }
    }
}
.concept-cafe {
    margin-bottom: 155px;
    @include media-down(md) {
        margin-bottom: 110px;
    }
    &__wrap {
        @include media-up(lg) {
            display: flex;
            flex-direction: row-reverse;
            align-items: flex-end;
        }
        @include media-down(lg) {
        }
    }
    &__image {
        position: relative;
        height: 450px;
        @include media-up(lg) {
            width: 50%;
        }
        @include media-down(lg) {
            width: calc(100% - 70px);
            margin-left: auto;
            margin-bottom: 200px;
        }
        @include media-down(md) {
            height: 370px;
        }
        @include media-down(sm) {
            height: 200px;
        }
        ._main-image {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
            @include media-down(lg) {
            }
        }
        ._sub-image {
            position: absolute;
            @include media-up(lg) {
                left: -183px;
                bottom: -250px;
            }
            @include media-down(lg) {
                bottom: -165px;
                left: -80px;
                width: 293px;
                height: auto;
            }
        }
    }
    &__content {
        @include media-up(lg) {
            width: 50%;
            padding-left: 32px;
        }
        @include media-down(lg) {
            padding-left: 32px;
        }
        ._text {
            width: 100%;
            max-width: 510px;
            margin-left: auto;
            @include media-down(md) {
                margin-left: 0;
            }
        }
        h3 {
            position: relative;
            z-index: 1;
            margin-bottom: 35px;
            font-size: calc(22 / 16 * $font-size-base);
            font-weight: bold;
            letter-spacing: 0.2em;
            line-height: calc(36 / 22);
            @include media-down(md) {
                margin-bottom: 25px;
                font-size: calc(18 / 16 * $font-size-base);
            }
            img {
                position: absolute;
                z-index: -1;
                @include media-up(lg) {
                    top: -50px;
                    left: 256px;
                }
                @include media-down(lg) {
                    top: -27px;
                    left: -32px;
                    width: 175px;
                    height: auto;
                }
            }
        }
        p {
            font-size: calc(16 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            letter-spacing: 0.15em;
            @include media-down(md) {
                font-size: calc(13 / 16 * $font-size-base);
            }
            &:not(:last-of-type) {
                margin-bottom: 30px;
                @include media-down(md) {
                    margin-bottom: 22px;
                }
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    &__btnwrap {
        margin-top: 160px;
        padding-inline: map-get($spacers, 3);
        @include media-down(lg) {
            margin-top: 25px;
        }
        ._btn-inner {
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: flex-end;
            width: 100%;
            max-width: 1100px;
            margin-inline: auto;
        }
    }
    &__btn {
        @include c-button-border();
    }
    ._affect-image {
        position: relative;
        width: 100%;
        max-width: 1200px;
        height: 600px;
        margin-inline: auto;
        @include media-down(lg) {
            height: 540px;
        }
        @include media-down(md) {
            height: 440px;
        }
        @include media-down(sm) {
            height: 650px;
            margin-top: 18px;
        }
        [class*='_image'] {
            position: absolute;
            height: auto;
        }
        ._image1 {
            top: -135px;
            left: 0;
            max-width: 60vw;
            @include media-down(md) {
                top: -50px;
            }
            @include media-down(sm) {
                top: 0;
                width: 100%;
                max-width: inherit;
            }
        }
        ._image2 {
            top: 27px;
            right: 26.3%;
            max-width: 18vw;
            @include media-down(sm) {
                top: 302px;
                right: 170px;
                width: 108px;
                max-width: inherit;
            }
        }
        ._image3 {
            top: 130px;
            right: 0;
            max-width: 24vw;
            @include media-down(sm) {
                top: inherit;
                bottom: 137px;
                width: 146px;
                max-width: inherit;
            }
        }
        ._image4 {
            bottom: 0;
            right: 370px;
            max-width: 22vw;
            @include media-down(sm) {
                right: inherit;
                left: 45px;
                width: 136px;
                max-width: inherit;
            }
        }
    }
    ._image-last {
        display: flex;
        justify-content: center;
        margin-top: 95px;
        @include media-down(md) {
            margin-top: 60px;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
}
.concept-goods {
    overflow: hidden;
    padding-bottom: 150px;
    @include media-down(md) {
        padding-bottom: 105px;
    }
    &__wrap {
        @include media-up(lg) {
            display: flex;
            align-items: flex-end;
        }
        @include media-down(lg) {
        }
    }
    &__image {
        position: relative;
        height: 450px;
        @include media-up(lg) {
            width: 50%;
        }
        @include media-down(lg) {
            width: calc(100% - 70px);
            margin-right: auto;
            margin-bottom: 60px;
        }
        @include media-down(md) {
            height: 370px;
        }
        @include media-down(sm) {
            height: 200px;
        }
        ._main-image {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
            @include media-down(lg) {
            }
        }
        ._sub-image {
            position: absolute;
            @include media-up(lg) {
                left: -183px;
                bottom: -250px;
            }
            @include media-down(lg) {
                bottom: -165px;
                left: -80px;
                width: 293px;
                height: auto;
            }
        }
    }
    &__content {
        @include media-up(lg) {
            width: 50%;
            padding-right: 32px;
        }
        @include media-down(lg) {
            padding-left: 32px;
        }
        ._text {
            width: 100%;
            margin-right: auto;
            padding: 0 0 50px 130px;
            @media (max-width: 1080px) {
                padding-left: 60px;
            }
            @include media-down(lg) {
                padding-left: 0;
                padding-bottom: 0;
            }
        }
        h3 {
            position: relative;
            z-index: 1;
            margin-bottom: 35px;
            font-size: calc(22 / 16 * $font-size-base);
            font-weight: bold;
            letter-spacing: 0.2em;
            line-height: calc(36 / 22);
            @include media-down(md) {
                margin-bottom: 25px;
                font-size: calc(18 / 16 * $font-size-base);
            }
            img {
                position: absolute;
                z-index: -1;
                max-width: inherit;
                @include media-up(lg) {
                    top: -50px;
                    left: -220px;
                }
                @include media-down(lg) {
                    top: -24px;
                    left: 123px;
                    width: 249px;
                    height: auto;
                }
                @include media-down(sm) {
                    right: 0;
                    left: inherit;
                }
            }
        }
        p {
            font-size: calc(16 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            letter-spacing: 0.15em;
            @include media-down(md) {
                font-size: calc(13 / 16 * $font-size-base);
            }
            &:not(:last-of-type) {
                margin-bottom: 30px;
                @include media-down(md) {
                    margin-bottom: 22px;
                }
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    ._affect-image {
        position: relative;
        height: 370px;
        padding-inline: 23px;
        @include media-down(lg) {
            height: 300px;
        }
        @include media-down(md) {
            height: auto;
            margin-top: 12px;
        }
        [class*='_image'] {
            @include media-up(md) {
                position: absolute;
            }
            @include media-down(md) {
                display: block;
                margin: auto;
            }
        }
        ._image1 {
            @include media-up(md) {
                top: 80px;
                right: 50%;
                max-width: 42vw;
            }
        }
        ._image2 {
            @include media-up(md) {
                bottom: 140px;
                left: calc(50% + 65px);
                max-width: 44vw;
            }
            @include media-down(sm) {
                width: 106%;
                max-width: inherit;
            }
        }
        ._image3 {
            bottom: -120px;
            @include media-up(md) {
                left: calc(50% + 145px);
                max-width: 35vw;
            }
            @include media-down(lg) {
                bottom: -100px;
            }
            @include media-down(md) {
                margin: -20px auto 50px;
            }
            @include media-down(sm) {
                width: 290px;
            }
        }
    }
    &__btnwrap {
        display: flex;
        justify-content: center;
        @include media-down(md) {
            margin-top: 55px;
        }
    }
    &__btn {
        @include c-button-reserve-store();
    }
}
.concept-temiyage {
    margin-bottom: 155px;
    @include media-down(md) {
        margin-bottom: 90px;
    }
    &__wrap {
        @include media-up(lg) {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
        }
        @include media-down(lg) {
        }
    }
    &__image {
        position: relative;
        height: 450px;
        @include media-up(lg) {
            width: 50%;
        }
        @include media-down(lg) {
            width: calc(100% - 70px);
            margin-left: auto;
            margin-bottom: 119px;
        }
        @include media-down(md) {
            height: 370px;
        }
        @include media-down(sm) {
            height: 200px;
        }
        ._main-image {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
            @include media-down(lg) {
            }
        }
        ._sub-image {
            position: absolute;
            @include media-up(lg) {
                bottom: -180px;
                left: -255px;
            }
            @include media-down(lg) {
                bottom: -111px;
                left: -52px;
                width: 180px;
                height: auto;
            }
            @include media-down(md) {
            }
        }
    }
    &__content {
        @include media-up(lg) {
            width: 50%;
            padding-left: 32px;
        }
        @include media-down(lg) {
            padding-left: 70px;
        }
        ._text {
            width: 100%;
            max-width: 510px;
            margin-left: auto;
            @include media-down(lg) {
                max-width: 100%;
                margin-left: 0;
            }
        }
        h3 {
            position: relative;
            z-index: 1;
            margin-bottom: 35px;
            font-size: calc(22 / 16 * $font-size-base);
            font-weight: bold;
            letter-spacing: 0.2em;
            line-height: calc(36 / 22);
            @include media-down(md) {
                margin-bottom: 30px;
                font-size: calc(18 / 16 * $font-size-base);
            }
            img {
                position: absolute;
                z-index: -1;
                max-width: inherit;
                @include media-up(lg) {
                    top: -48px;
                    left: 26px;
                }
                @include media-down(lg) {
                    top: -27px;
                    left: -70px;
                    width: 357px;
                    height: auto;
                }
            }
        }
        p {
            font-size: calc(16 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            letter-spacing: 0.15em;
            @include media-down(md) {
                font-size: calc(13 / 16 * $font-size-base);
            }
            &:not(:last-of-type) {
                margin-bottom: 30px;
                @include media-down(md) {
                    margin-bottom: 22px;
                }
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    &__btnwrap {
        margin-top: 77px;
        padding-inline: map-get($spacers, 3);
        @include media-down(lg) {
            margin-top: 25px;
        }
        ._btn-inner {
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: flex-end;
            width: 100%;
            max-width: 1100px;
            margin-inline: auto;
        }
    }
    &__btn {
        @include c-button-border();
    }
}
.concept-takeout {
    overflow: hidden;
    @include media-down(md) {
        margin-bottom: 105px;
    }
    &__wrap {
        @include media-up(lg) {
            display: flex;
            align-items: center;
        }
    }
    &__image {
        position: relative;
        height: 450px;
        @include media-up(lg) {
            width: 50%;
        }
        @include media-down(lg) {
            width: calc(100% - 70px);
            margin-right: auto;
            margin-bottom: 100px;
        }
        @include media-down(md) {
            height: 370px;
        }
        @include media-down(sm) {
            height: 200px;
        }
        ._main-image {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
            @include media-down(lg) {
            }
        }
        ._sub-image {
            position: absolute;
            @include media-up(lg) {
                right: -160px;
                bottom: -169px;
            }
            @include media-down(lg) {
                bottom: -67px;
                right: -90px;
                width: 250px;
                height: auto;
            }
            @include media-down(md) {
                right: -80px;
                width: 200px;
            }
            @include media-down(sm) {
                width: 150px;
            }
        }
    }
    &__content {
        position: relative;
        @include media-up(lg) {
            width: 50%;
            padding-right: 32px;
        }
        @include media-down(lg) {
            width: 100%;
            padding-right: 32px;
        }
        ._text {
            width: 100%;
            max-width: 510px;
            margin-right: auto;
            padding-left: 130px;
            @media (max-width: 1080px) {
                padding-left: 60px;
            }
            @include media-down(lg) {
                max-width: 100%;
            }
            @include media-down(md) {
                padding-left: 32px;
            }
        }
        h3 {
            position: relative;
            z-index: 1;
            margin-bottom: 15px;
            font-size: calc(22 / 16 * $font-size-base);
            font-weight: bold;
            letter-spacing: 0.2em;
            line-height: calc(36 / 22);
            @include media-down(md) {
                margin-bottom: 30px;
                font-size: calc(18 / 16 * $font-size-base);
            }
            img {
                position: absolute;
                z-index: -1;
                max-width: inherit;
                @include media-up(lg) {
                    top: -50px;
                    left: -270px;
                }
                @include media-down(lg) {
                    top: -27px;
                    left: -7px;
                    width: 350px;
                    height: auto;
                }
            }
        }
        p {
            font-size: calc(16 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            letter-spacing: 0.15em;
            @include media-down(md) {
                font-size: calc(13 / 16 * $font-size-base);
            }
            &:not(:last-of-type) {
                margin-bottom: 30px;
                @include media-down(md) {
                    margin-bottom: 22px;
                }
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        ._image2 {
            position: absolute;
            top: calc(100% - 30px);
            left: 40%;
            max-width: inherit;
            @include media-down(lg) {
                top: inherit;
                bottom: -20px;
                left: inherit;
                right: 0;
                width: 300px;
            }
            @include media-down(md) {
                position: static;
                display: block;
                width: 210px;
                margin-left: auto;
            }
            @include media-down(sm) {
                margin-top: -10px;
                margin-right: -32px;
            }
        }
    }
    &__btnwrap {
        margin-top: 215px;
        padding-inline: map-get($spacers, 3);
        @include media-down(lg) {
            margin-top: 25px;
        }
        @include media-down(md) {
            margin-top: 0;
        }
        ._btn-inner {
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: flex-end;
            width: 100%;
            max-width: 1100px;
            margin-inline: auto;
        }
    }
    &__btn {
        @include c-button-border();
    }
}
.concept-image {
    height: 410px;
    background: url(/assets/img/webp/concept/pho-image@2x.webp) no-repeat center /
        cover;
    @include media-down(lg) {
        height: 220px;
    }
    @include media-down(md) {
        height: 170px;
        margin-top: 75px;
    }
    @include media-down(sm) {
        height: 110px;
    }
}
.concept-attemp {
    width: 100%;
    max-width: 1240px;
    margin-inline: auto;
    padding: 85px 0 70px;
    background-color: rgba(68, 61, 62, 0.1);
    @include media-down(md) {
        padding: 45px 0;
    }
    ._title {
        margin-bottom: 20px;
        font-size: calc(22 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        letter-spacing: 0.2em;
        line-height: calc(36 / 22);
        @include media-down(md) {
            font-size: calc(18 / 16 * $font-size-base);
            line-height: calc(29 / 18);
        }
    }
    ._text {
        margin-bottom: 65px;
        font-size: calc(16 / 16 * $font-size-base);
        font-feature-settings: 'palt';
        text-align: center;
        letter-spacing: 0.15em;
        line-height: calc(30 / 16);
        @include media-down(md) {
            margin-bottom: 60px;
            font-size: calc(13 / 16 * $font-size-base);
            line-height: calc(24 / 13);
        }
    }
    ._list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        list-style-type: none;
        margin: 0;
        padding: 0 70px;
        @include media-down(lg) {
            padding-inline: 45px;
        }
        @include media-down(md) {
            grid-template-columns: 1fr;
        }
        li {
            background-color: #fff;
            @include media-down(md) {
                width: 100%;
                max-width: 350px;
                margin: auto;
                min-height: 500px;
            }
        }
        div {
            padding: 30px 0;
            @include media-down(md) {
                padding: 25px 0;
            }
        }
        img {
            width: 100%;
            height: auto;
        }
        h3 {
            margin-bottom: 25px;
            padding: 0 5px;
            font-size: calc(18 / 16 * $font-size-base);
            font-weight: bold;
            text-align: center;
            letter-spacing: 0.075em;
            line-height: calc(20 / 18);
            @include media-down(lg) {
                margin-bottom: 22px;
                font-size: calc(15 / 16 * $font-size-base);
            }
        }
        p {
            margin: 0;
            padding-inline: 30px;
            font-size: calc(15 / 16 * $font-size-base);
            letter-spacing: 0.06em;
            line-height: calc(28 / 15);
            @include media-down(lg) {
                padding-inline: 31px;
                font-size: calc(12 / 16 * $font-size-base);
            }
        }
        a {
            @include c-button-arrow();
            margin-top: 30px;
        }
    }
}
.concept-staff {
    margin: 110px 0;
    padding-inline: map-get($spacers, 3);
    @include media-down(md) {
        margin: 80px 0;
    }
    &__inner {
        width: 100%;
        max-width: 1100px;
        margin-inline: auto;
    }
    ._list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 70px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        @include media-down(xl) {
            gap: 50px;
        }
        @include media-down(lg) {
            grid-template-columns: 1fr;
        }
        ._body {
            @include media-up(md) {
                display: flex;
                gap: 34px;
                flex-direction: row-reverse;
            }
        }
        ._image {
            flex-shrink: 0;
            @include media-down(md) {
                display: flex;
                align-items: center;
                gap: 22px;
            }
            img {
                @include media-down(sm) {
                    width: 145px;
                    height: auto;
                }
            }
        }
        h3 {
            margin-bottom: 30px;
            font-size: calc(18 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            font-weight: bold;
            letter-spacing: 0.1em;
            line-height: calc(28 / 18);
            @include media-down(md) {
                margin-bottom: 15px;
                font-size: calc(15 / 16 * $font-size-base);
                // letter-spacing: 0.06em;
                line-height: calc(24 / 15);
            }
        }
        ._text {
            font-size: calc(16 / 16 * $font-size-base);
            letter-spacing: 0.1em;
            line-height: calc(30 / 16);
            @include media-up(md) {
                margin-top: -6px;
            }
            @include media-down(md) {
                margin-bottom: 25px;
                font-size: calc(12 / 16 * $font-size-base);
                line-height: calc(24 / 12);
            }
        }
        ._position {
            margin: 14px 0 0;
            font-size: calc(13 / 16 * $font-size-base);
            letter-spacing: 0.1em;
            line-height: calc(22 / 13);
            @include media-down(md) {
                margin: 0;
                font-size: calc(10 / 16 * $font-size-base);
            }
        }
        ._nama {
            display: flex;
            align-items: flex-end;
            gap: 17px;
            font-size: calc(18 / 16 * $font-size-base);
            letter-spacing: 0.075em;
            line-height: calc(26 / 18);
            @include media-down(md) {
                flex-wrap: wrap;
                gap: 0 13px;
                margin: 0;
                font-size: calc(15 / 16 * $font-size-base);
            }
            span {
                font-size: calc(12 / 16 * $font-size-base);
                @include media-down(md) {
                    font-size: calc(10 / 16 * $font-size-base);
                }
            }
        }
    }
}
.concept-lastimage {
    @include media-down(md) {
        position: relative;
        bottom: -1px;
        z-index: -1;
    }
    img {
        width: 100%;
        height: auto;
    }
}
